.process-user-page {
  .main-process-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-width: 100%;

    .custom-search {
      width: 15rem;
    }
  }
  .process-content {
    background-color: white;
    border-radius: 5px;

    .grid-container {
      display: grid;
      padding-left: "0.7rem";
      padding-right: "0.7rem";
      min-height: "47rem";

      .no-data-check {
        max-width: 100%;
        height: 47rem;
      }
    }

    .custom-loader {
      max-width: 100%;
      height: 47rem;
    }
  }
}
