#stocks-carousel {
  background-color: white;

  .carousel-indicators {
    margin-bottom: 0px !important;
  }
  .stocks-dropbtn {
    .dropdown-item {
      color: black;
    }
    .dropdown-item:active {
      color: white;
    }
  }
}

.stocks-dropbtn {
  .dropdown-toggle {
    color: black;
    font-size: 23px;
    font-weight: bold;
    --bs-btn-hover-color: black;
    --bs-btn-active-bg: white;
  }
  .dropdown-menu {
    border-radius: 0px;
    --bs-dropdown-link-active-bg: #641e8c;
  }
}

.neg-percent {
  color: red;
  background-color: rgb(255, 0, 0, 0.1);
  text-align: center;
  border-radius: 30px;
}

.pos-percent {
  color: #1abc00;
  background-color: rgb(0, 128, 0, 0.1);
  text-align: center;
  border-radius: 30px;
}
