//#  Small Devices
@media only screen and (min-width: 600px) {
}

//# Small Desktops
@media only screen and (min-width: 992px) {
  .user-config-modal {
    .modal-dialog {
      max-width: 100%;
    }
  }
  .layout-dashboard-grid-layout {
    min-height: 300px !important;
    min-width: 2000px !important;
  }

  .main-card {
    width: 100%;
  }

  .main-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }

  .fav-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }

  .stocks-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }

  .lunch-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }
}

//# Large desktops
@media only screen and (min-width: 1200px) {
  .user-config-modal {
    .modal-dialog {
      min-width: 100%;
    }

    .react-grid-item.cssTransforms {
      max-width: 100%;
    }
    .main-card {
      width: 100%;
    }
  }

  .main-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }

  .fav-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }
  .stocks-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }
  .lunch-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }

  .sac-placeholder-div {
    .component-container {
      max-width: 100%;
    }
  }
}

.user-config-modal {
  .modal-content {
    border-radius: 0px;
    padding: 20px;
  }

  .card-div {
    color: black;
    padding: 2rem;
    font-weight: 600;
    font-size: larger;
    text-align: center;
    background: #efeff0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
  }

  .card-div:hover {
    background-color: #ecd1fd;
    border: 1px solid #8a00e5 !important;
    border-radius: 1rem;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    cursor: pointer;
    padding: 3rem;
  }

  .layout-dashboard-grid-layout {
    min-height: 300px !important;
    min-width: 2000px !important;
  }

  .leftSide {
    border-right: none !important;
  }

  .left-side-inner-div {
    background-color: #f4f4f4 !important;
    width: 100%;
    min-height: 115vh;
  }
  .right-side-div {
    background-color: #f4f4f4 !important;
    margin-left: 20px;
    padding-top: 2.5rem;
  }

  .search-widgets {
    border-radius: 10px !important;
    width: 100% !important;
    font-size: 20px !important;
    height: 3rem !important;
  }

  .main-card {
    fill: #fff;
    filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
    max-width: 100%;
  }
  .main-card-50 {
    fill: #fff;
    filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
    max-width: 100%;
  }
  .modal-left-title {
    color: #8a00e5 !important;
    font-weight: 600;
    font-size: 2rem;
  }

  .modal-right-title {
    font-weight: 600;
    font-size: 2rem;
  }

  .modal-cancel-btn {
    margin-right: 20px;
  }

  #save-config {
    margin-top: 0;
  }

  .delete-div {
    background-color: #f7e9ff;
  }
  .common-save-btn {
    background-color: #8a00e5;
    color: white;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    cursor: pointer;
    border: 2px solid #8a00e5;
    border-radius: 30px;
    letter-spacing: 0.2px;
    font-weight: 600;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: 0.2s ease;
  }

  .common-save-btn:hover {
    background-color: #641e8c;
    color: white;
    border-color: #641e8c;
  }

  .grid-container {
    width: 100%;
    min-height: 300px;
  }
  .layout {
    margin-left: auto;
    margin-right: auto;
  }
  .grid-item {
    border: 1px solid #3333;
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }

  .react-grid-item {
    transition: all 200ms ease;
    // transition-property: left, top, width, height;
  }

  .react-grid-item img {
    pointer-events: none;
    user-select: none;
  }

  .react-grid-item.cssTransforms {
    transition-property: transform, width, height;
    min-height: 250px;
  }

  .display-css {
    display: flex;
    justify-content: space-evenly;
  }

  .react-grid-item.resizing {
    transition: none;
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.dropping {
    visibility: hidden;
  }

  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item.react-grid-placeholder.placeholder-resizing {
    transition: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }

  .react-resizable {
    position: relative;
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }

  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }

  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }

  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }

  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }

  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }

  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }

  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }

  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }
}

.main-placeholder-div {
  background-color: white;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
  min-height: -webkit-fill-available;
  justify-content: space-between;
  max-width: 100%;

  .placeholder-title {
    font-size: 26px;
    color: #8a00e5;
    padding-left: 1rem;
    font-weight: 500;
  }

  .first-div {
    background-color: #d9d9d9;
    min-width: 25%;
    height: 6rem;
  }

  .middle-div {
    background-color: #d9d9d9;
    min-width: 25%;
    height: 3rem;
  }

  .last-div {
    background-color: #d9d9d9;
    min-width: 25%;
    height: 1rem;
  }

  .delete-div {
    background-color: #f7e9ff !important;
  }

  .widget-delete-btn {
    float: right;
    font-weight: bold;
    background: #f7e9ff;
    border: none;
    --bs-btn-hover-color: #641e8c;
    --bs-btn-hover-bg: #f7e9ff;
    --bs-btn-hover-border-color: #641e8c;
    --bs-btn-active-color: #641e8c;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #641e8c;
    height: --webkit-fill-available !important;
  }

  .widget-delete-btn :hover {
    color: none;
    background-color: #f7e9ff !important;
    border-color: #f7e9ff !important;
  }
  .widget-delete-btn :active {
    --bs-btn-hover-bg: #f7e9ff;
  }
  .component-layout {
    flex-wrap: nowrap !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.stocks-placeholder-div {
  background-color: white;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
  min-height: -webkit-fill-available;
  justify-content: space-between;
  max-width: 100%;

  .placeholder-title {
    font-size: 26px;
    color: #8a00e5;
    padding-left: 1rem;
    font-weight: 600;
  }
  .component-container {
    padding: inherit;
    .first-div {
      background-color: #d9d9d9;

      height: 3rem;
    }

    .middle-div {
      background-color: #d9d9d9;
      height: 8rem;
    }
    .single-div {
      background-color: #d9d9d9;
      height: 12rem;
    }
  }

  .delete-div {
    background-color: #f7e9ff !important;
  }

  .widget-delete-btn {
    float: right;
    font-weight: bold;
    background: #f7e9ff;
    border: none;
    --bs-btn-hover-color: #641e8c;
    --bs-btn-hover-bg: #f7e9ff;
    --bs-btn-hover-border-color: #641e8c;
    --bs-btn-active-color: #641e8c;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #641e8c;
    height: --webkit-fill-available !important;
  }

  .widget-delete-btn :hover {
    color: none;
    background-color: #f7e9ff !important;
    border-color: #f7e9ff !important;
  }
  .widget-delete-btn :active {
    --bs-btn-hover-bg: #f7e9ff;
  }
  .component-layout {
    flex-wrap: nowrap !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.fav-placeholder-div {
  background-color: white;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
  min-height: -webkit-fill-available;
  justify-content: space-between;
  max-width: 100%;

  .placeholder-title {
    font-size: 26px;
    color: #8a00e5;
    padding-left: 1rem;
    font-weight: 600;
  }

  .first-div {
    background-color: #d9d9d9;
    height: 6rem;
  }

  .middle-div {
    background-color: #d9d9d9;
    height: 3rem;
  }

  .last-div {
    background-color: #d9d9d9;
    height: 1.5rem;
  }

  .delete-div {
    background-color: #f7e9ff !important;
  }

  .widget-delete-btn {
    float: right;
    font-weight: bold;
    background: #f7e9ff;
    border: none;
    --bs-btn-hover-color: #641e8c;
    --bs-btn-hover-bg: #f7e9ff;
    --bs-btn-hover-border-color: #641e8c;
    --bs-btn-active-color: #641e8c;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #641e8c;
    height: --webkit-fill-available !important;
  }

  .widget-delete-btn :hover {
    color: none;
    background-color: #f7e9ff !important;
    border-color: #f7e9ff !important;
  }
  .widget-delete-btn :active {
    --bs-btn-hover-bg: #f7e9ff;
  }
  .component-layout {
    flex-wrap: nowrap !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.lunch-placeholder-div {
  background-color: white;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
  min-height: -webkit-fill-available;
  justify-content: space-between;
  max-width: 100%;

  .placeholder-title {
    font-size: 26px;
    color: #8a00e5;
    padding-left: 1rem;
    font-weight: 600;
  }

  .first-div {
    background-color: #d9d9d9;
    height: 2rem;
  }

  .lunch-option {
    background-color: #d9d9d9;
    height: 9rem;
  }

  .lunch-option-div {
    flex-wrap: nowrap;
  }

  .delete-div {
    background-color: #f7e9ff !important;
  }

  .widget-delete-btn {
    float: right;
    font-weight: bold;
    background: #f7e9ff;
    border: none;
    --bs-btn-hover-color: #641e8c;
    --bs-btn-hover-bg: #f7e9ff;
    --bs-btn-hover-border-color: #641e8c;
    --bs-btn-active-color: #641e8c;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #641e8c;
    height: --webkit-fill-available !important;
  }

  .widget-delete-btn :hover {
    color: none;
    background-color: #f7e9ff !important;
    border-color: #f7e9ff !important;
  }
  .widget-delete-btn :active {
    --bs-btn-hover-bg: #f7e9ff;
  }
  .component-layout {
    flex-wrap: nowrap !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.sac-placeholder-div {
  background-color: white;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.15));
  min-height: -webkit-fill-available;
  justify-content: space-between;
  max-width: 100%;

  .placeholder-title {
    font-size: 26px;
    color: #8a00e5;
    padding-left: 1rem;
    font-weight: 600;
  }

  .first-div {
    background-color: #d9d9d9;
    height: 2rem;
    width: 100%;
  }

  .links {
    background-color: #d9d9d9;
    height: 1rem;
    max-width: 100%;
    border-radius: 20px;
  }

  .iframe-div {
    background-color: #d9d9d9;
    height: 8rem;
    max-width: 100%;
  }

  .delete-div {
    background-color: #f7e9ff !important;
  }

  .widget-delete-btn {
    float: right;
    font-weight: bold;
    background: #f7e9ff;
    border: none;
    --bs-btn-hover-color: #641e8c;
    --bs-btn-hover-bg: #f7e9ff;
    --bs-btn-hover-border-color: #641e8c;
    --bs-btn-active-color: #641e8c;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #641e8c;
    height: --webkit-fill-available !important;
  }

  .widget-delete-btn :hover {
    color: none;
    background-color: #f7e9ff !important;
    border-color: #f7e9ff !important;
  }
  .widget-delete-btn :active {
    --bs-btn-hover-bg: #f7e9ff;
  }
  .component-layout {
    flex-wrap: nowrap !important;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.donut-chart-div {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 20px solid #d9d9d9;
}

.status-card-div {
  background-color: #d9d9d9;
}
