.main-sac-div {
  max-height: 33rem;
  background-color: #f6f4ff;
  margin: 1rem;

  .grid-container {
    display: grid;
  }

  .links-container {
    display: grid;

    .list-item {
      margin-right: 2rem;
    }
  }

  .links {
    background-color: #8a00e5;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0.5%;
    margin-right: 0.5%;
    border-radius: 2rem;

    .list-item {
      display: flex;
      margin-left: 1%;
      margin-right: 1%;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
    }

    .list-name,
    .active .list-name {
      flex-grow: 1;
      text-align: center;
      color: #8a00e5;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 30px;
      cursor: pointer;
    }

    .active {
      display: flex;
      border-radius: 2rem;
      background-color: white;
      border: 3px solid #8a00e5;
      height: 30px;
    }

    .delete-btn,
    .active .delete-btn {
      height: 30px !important;
      border: 0px;
      border-radius: 2rem;
      color: #8a00e5;
      background: none;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      margin-left: 10px;
    }

    .delete-btn:hover,
    .active .delete-btn:hover {
      background: none;
    }

    .delete-btn:active,
    .active .delete-btn:active {
      color: white !important;
      background: none;
    }

    .delete-btn:disabled,
    .active .delete-btn:disabled {
      color: white !important;
      background: none;
    }

    .list-name {
      color: white;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 30px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      cursor: pointer;
    }

    .list-name:hover {
      text-decoration: underline;
    }

    .delete-btn {
      height: 30px !important;
      border: 0px;
      border-radius: 2rem;
      color: white;
      background-color: none !important;
      background: none;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: none;
      }
      &:active {
        color: white !important;
        background: none;
      }
      &:disabled {
        color: white !important;
        background: none;
      }
    }
  }

  .maximize-btn-div {
    cursor: pointer;
  }

  .app-primary-dropdown .react-dropdown-select {
    min-height: 20px !important;
    height: 33px !important;

    .react-dropdown-select-dropdown-handle {
      margin-top: 3px !important;
    }
  }

  .no-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 22rem;
    background-color: white;
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 200;
    padding: 1rem;
    box-sizing: border-box;
  }
}

@media (min-width: 992px) {
  .custom-modal {
    max-width: 80% !important;
    max-height: 70% !important;
  }
}

.custom-modal {
  max-width: 80% !important;
  max-height: 80% !important;
}

@media (max-width: 768px) {
  .custom-modal {
    max-width: 80% !important;
  }
}

@media (max-width: 576px) {
  .custom-modal {
    max-width: 80% !important;
    max-height: 80% !important;
  }
}

@media (max-width: 375px) {
  .custom-modal {
    max-width: 80% !important;
    max-height: 80% !important;
  }
}
