.main-footer {
  background-color: #1b1534;
  color: white;
  width: 100%;
  height: 20rem;
  margin-top: auto;
}

.footer-base {
  padding-top: 4rem;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.footer-button {
  .btn-primary {
    --bs-btn-color: #1b1734;
    --bs-btn-bg: white;
    --bs-btn-border-color: white;
    --bs-btn-hover-color: #1b1734;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-border-color: white;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #1b1734;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;
    border-radius: 30px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 500;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    transition: 0.2s;
  }

  .btn-primary:hover {
    outline-offset: 6px;
    outline: 2px solid white;
  }
}

.social-icons {
  font-size: 1.5rem;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.footer-linklist {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  line-height: 1.1428571429;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.footer-spacer {
  display: block;
  margin-top: 0;
  margin-bottom: 1.5rem;
  height: 2px;
  width: 100%;
  background: #fff;
}

.footer-copy {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}
