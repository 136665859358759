.main-page-margin {
  .process-table {
    .user-table {
      padding-top: 1rem;

      .table {
        display: block;
        empty-cells: show;
        --bs-table-striped-bg: none !important;

        thead {
          background: #ffff !important;
          color: black;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 0;

          .table-row {
            height: 3.5rem;
          }
        }

        tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: hidden;
          min-height: 38rem;
          text-align: center;

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        .more-user-btn {
          border: none;
          background: none;
          color: #8a00e5;
          font-size: small;
        }

        .more-user-btn:hover {
          text-decoration: underline;
        }

        tr {
          width: 100%;
          display: flex;
          border: none;
          height: 3.4rem;
        }

        th {
          display: block;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:first-child {
          text-align: left;
          padding-left: 1rem;
          width: 17%;
        }

        th:nth-child(2) {
          text-align: left;
          width: 20%;
          background-color: #ffff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:nth-child(3) {
          text-align: left;
          width: 11%;

          .period-dropdown {
            position: absolute;
            width: 10%;
          }
        }

        th:nth-child(4) {
          text-align: left;
          width: 16%;
        }

        th:nth-child(5) {
          text-align: left;
          width: 30%;

          .org-dropdown {
            position: absolute;
            width: 28%;
          }
        }

        th:last-child {
          text-align: left;
          width: 6%;

          .disabled-dropdown {
            height: 32px !important;
            width: 95% !important;
          }
        }

        td {
          vertical-align: left;
          border: none !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 1rem;
          text-align: left;
          width: 17%;

          .process-name {
            text-decoration: none !important;
          }
        }

        td:nth-child(2) {
          text-align: left;
          width: 20%;
        }

        td:nth-child(3) {
          width: 11%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        td:nth-child(4) {
          text-align: left;
          width: 16%;

          .table-owner {
            height: 2rem;
            width: 2rem;
            border: 1.5px solid black;
            object-fit: contain;
            background: #5f5f5f;
            border-radius: 2.2rem;
          }
        }

        td:nth-child(5) {
          text-align: left;
          width: 30%;
        }

        td:last-child {
          text-align: left;
          width: 6%;
        }
      }

      .process-custom-loader {
        max-width: 100%;
        height: 79vh;
      }
    }
  }
}
