.announcement-carousel {
  .card {
    height: 23.5rem !important;
    max-width: 33.33%;

    .description-section {
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      -webkit-line-clamp: 4 !important;
      min-height: 5.7rem !important;
      max-height: 6.7rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
  }
}

.carousel-loader {
  max-width: 100%;
  height: 24rem;
}
