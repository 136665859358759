$node-width: 30rem;
$node-height: 30px;
$node-background-color: #ffcc00;
$node-border-color: #333;
$node-border-radius: 4px;

.vis-network {
  .square {
    width: $node-width;
    height: $node-height;
    background-color: $node-background-color;
    border: 2px solid $node-border-color;
    border-radius: $node-border-radius;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
  }
}
