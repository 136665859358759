.custom-dropdown-main {
  .dropdown-title {
    font-size: 21px;
    font-weight: 600;
  }

  .dropdown-btn {
    cursor: pointer;
  }

  #dropdown-basic :active {
    background-color: none !important;
  }

  .btn:first-child:active {
    background-color: none !important;
  }

  .btn-primary {
    --bs-btn-active-bg: none !important;
  }

  .custom-dropdown {
    --bs-dropdown-min-width: 33rem;
    padding: 15px;

    .profile-img {
      height: 2rem;
      width: 2rem;
      border: 1.5px solid black;
      border-radius: 7rem;
      object-fit: contain;
    }
    .user-name {
      padding-top: 0.3rem;
    }
  }
}

.dropdown-select .react-dropdown-select-dropdown {
  width: 100% !important;
  z-index: 999;
}

.custom-typeahead .rbt-input-main {
  font-size: 14px;
  width: auto !important;
  flex-grow: 1 !important;
}

.emp-details {
  .user-name {
    font-weight: bold;
  }
  .user-org-code {
    color: #8900e4;
    font-size: 14px;
  }
}
.custom-typeahead {
  position: relative;
  padding-right: 20px;
  padding-bottom: 5px;
  .rbt-menu {
    margin-left: -7px;
    width: 495px !important;
    border: 1px solid #ccc;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 99;
    margin-top: 5px;
  }
}

.filter-badge {
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 6px 4px 6px;
  font-size: 12px;
}
