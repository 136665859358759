.main-learning-details-page {
  .back-btn {
    text-decoration: none !important;
    color: black !important;
  }

  .course-title {
    font-size: 20px;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .owner-course-title {
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    font-size: 15px;
  }
  .course-img {
    height: 6.8rem;
    width: 10rem;
    padding-right: 0.5rem;
  }

  .section-split {
    border-bottom: 2px solid black;
  }

  .section-content-align {
    margin-top: 2.5px;
  }

  .grid-connections {
    width: 95%;
  }

  .grid-links {
    width: 33%;
    height: 23px;
  }

  .desc-overflow {
    max-height: 100px;
    overflow: hidden;
    .pro-desc {
      white-space: pre-line;
    }
  }

  .read-more-btn {
    cursor: pointer;
    color: #8a00e5;
  }

  .learning-tags {
    background-color: #372ca2;
    color: #fff;
    padding: 10px 10px;
    border-radius: 1.25rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    vertical-align: middle;
    width: fit-content;
    margin-top: 0;
    margin-bottom: 0;
  }

  .source {
    padding-top: 3px;
  }
  .align-content {
    margin-top: 1px;
  }

  .padding-left {
    padding-left: 0.8rem !important;
  }

  .inline-ellipsis {
    display: inline-block; /* Align the div inline with the text */
    max-width: 200px; /* Set a specific max width for truncation */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis (...) when content overflows */
    white-space: nowrap; /* Prevent text wrapping to the next line */
  }
}
