.org-table {
  margin: 0 auto;
  display: flex;
}

.org-table-body {
  height: 300px !important;

  .peopleCol {
    color: black !important;
  }
}

.org-table-head {
  th {
    flex-basis: auto !important;
  }
}

.org-table thead {
  background-color: #2e2b60;
  color: white;
}

.table {
  --bs-table-striped-bg: #e0dff1;
  text-align: left;
}
