.trending-learnings {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    left: 100% !important;
  }

  .trending-learnings-carousel {
    .card {
      height: max-content;
      max-width: 33.33%;
    }
  }
  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    right: 100% !important;
    left: auto !important;
  }
  max-width: 100%;

  .carousel-indicators [data-bs-target] {
    margin-bottom: -32px;
    background-color: #8a00e5;
  }

  .card .description-section {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: 15px;
    max-height: 3.5rem;
    min-height: 4.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
