.tools-page {
  .tabs-section {
    .tools-tab-btn {
      font-weight: bold;
      background-color: transparent;
      border: 0px;
      border-radius: 0px;
    }
    .uiTab {
      border-bottom: 3px solid #8c7500;
      font-weight: 800;
    }
    .intTab {
      border-bottom: 3px solid #006c3b;
      font-weight: 800;
    }
    .sourceTab {
      border-bottom: 3px solid #00328c;
      font-weight: 800;
    }

    .noTab {
      border-bottom: 3px solid #9f9f9f;
      font-weight: 800;
    }
  }
  .nav-tabs {
    --bs-nav-tabs-link-hover-border-color: transparent;
    --bs-nav-tabs-link-active-border-color: transparent;
    font-size: 17px;
    font-weight: 600;
    .nav-link {
      color: black;
    }
    .nav-link.active {
      border-bottom: 4px solid black;
    }
  }
}
