.main {
  display: flex;
  top: 50%;
  justify-content: space-between;
  max-width: 92%;
  margin-left: 4%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .a {
    .nav-link {
      background: none;
      color: black;
      border: 0px;
      --bs-btn-active-color: black;
      --bs-btn-active-bg: white;
      --bs-btn-hover-color: black;
      --bs-btn-hover-bg: white;
      --bs-btn-padding-x: 0px;
      --bs-btn-padding-y: 0px;
      font-size: 2rem;
      font-weight: bold;
    }

    svg {
      font-size: 2.5rem;
    }

    .btn-secondary:hover {
      background-color: transparent;
      color: black;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.main-btn {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 92%;
  margin-left: 3%;

  .btn-secondary {
    background: none;
    color: black;
    border: 0px;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: white;
    --bs-btn-padding-x: 0px;
    --bs-btn-padding-y: 0px;
    font-size: 2rem;
    font-weight: bold;

    svg {
      font-size: 2.5rem;
    }
  }
}

.b {
  display: flex;
}

.fav-btn {
  background-color: white;
  padding: 10px;
  border-color: #cfcfcf;
  color: black;
}

.fav-btn:hover {
  background-color: #8a00e5;
  color: white;
}

#link-btn {
  border-right: 0px;
}

#fav-add-btn {
  background-color: white;
  padding: 10px;
  border-color: #8a00e5;
  color: black;
}

.form-switch {
  top: 10%;
}

.b .form-switch {
  margin-top: 4%;
}

.a .btn-secondary {
  background-color: white;
  color: black;
  border-radius: 0px;
  border-color: #cfcfcf;
  --bs-btn-focus-shadow-rgb: none;
  font-weight: 400;
}

.a .btn-secondary:hover {
  background-color: #8a00e5;
  color: white;
}

.a .btn-secondary:focus {
  background-color: #8a00e5;
  color: white;
}

.b .btn-secondary {
  border: 1.5px solid #8a00e5;
  border-radius: 0px;
  color: white;
  background: #8a00e5;
  --bs-btn-focus-shadow-rgb: none;
  margin-left: 20px;
  font-weight: 500;
}

.b .btn-secondary:hover {
  border: 1.5px solid #8a00e5;
  border-radius: 0px;
  color: #8a00e5;
  background-color: transparent;
}

.edit-btn {
  color: #8a00e5;
  background: transparent;
  border: 0px;
}

.table-inner {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  // border: 1px solid #cfcfcf;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 1.2rem;
}

.empty-folder {
  text-align: center;
  font-weight: bold;

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.my-table {
  border-spacing: 0px;
  width: 100%;
  // min-height: 40rem !important;

  caption {
    caption-side: top;
    color: black;
    font-size: 20px;
    font-weight: 500;
    padding-top: 3px;

    .modal-fav-add {
      float: right;
      border: 1.5px solid #8a00e5;
      border-radius: 2rem;
      color: #8a00e5;
      background: white;
      --bs-btn-focus-shadow-rgb: none;
      margin-left: 10px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      vertical-align: middle;
      line-height: 2em;
      width: 7rem;
      transition: 0.3s;
    }

    .modal-fav-add:hover {
      border: 1.5px solid #8a00e5;
      border-radius: 2rem;
      color: white;
      background-color: #8a00e5;
    }
  }

  .base-btn {
    all: unset;
    cursor: pointer;
    padding-top: 5px;
  }

  .base-btn:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    // color: black;
  }

  .empty-folder {
    text-align: center;
    width: 100%;
    font-weight: bold;

    p {
      margin-top: 1rem;
      font-weight: 600;

      button {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 3px;
      }
    }
  }

  thead {
    position: relative;
    display: block;
    width: 100%;
    font-size: 17px;
  }

  tbody {
    display: block;
    position: relative;
    width: 100%;
    min-height: 38.5rem;
    font-size: 16px;

    tr {
      height: 4rem !important;
    }

    .ellipsis {
      position: relative;

      .folder-name {
        cursor: pointer;
      }
    }

    .ellipsis span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 85%;
    }
  }

  th:first-child {
    padding-left: 0.7rem;
    width: 4%;
  }

  th:nth-child(2) {
    width: 35%;
  }

  th:nth-child(3) {
    width: 36%;
  }

  th:nth-child(4) {
    width: 37%;
  }

  th:last-child {
    width: 8%;
  }

  td:first-child {
    padding-left: 0.7rem;
    width: 4%;
  }

  td:nth-child(2) {
    width: 36%;
  }

  td:nth-child(3) {
    width: 38%;
  }

  td:nth-child(4) {
    width: 38%;
  }

  td:last-child {
    width: 7%;
  }

  tr {
    width: 100%;
    display: flex;
    border: none;
    background-color: #dedede;
    height: 3.5rem;
  }

  th {
    padding-top: 1rem;
    flex-grow: 2;
    display: block;
    text-align: left;
    background-color: white;
    color: black;
    font-weight: 700;
    border-bottom: 1px solid black;
  }

  td {
    background-color: #e8e8e8;
    width: 30%;
    padding-top: 0.5rem;
  }

  tr:nth-child(odd) > td {
    background-color: white;
    color: black;
  }

  .expand-btn {
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: #8a00e5;
  }

  a:hover {
    color: #6f42c1;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.expand-btn {
  background-color: Transparent;
  background-repeat: no-repeat;
  color: black;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-size: 2em;
  transition: transform 0.3s ease;
}

.expand-btn:hover {
  transform: scale(1.2);
}

.table-btn {
  background-color: Transparent;
  background-repeat: no-repeat;
  color: #8a00e5;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-size: 1em;
  transition: transform 0.3s ease;
}

.table-btn:hover {
  transform: scale(1.2);
}

.attribute-name {
  font-weight: bold;
  color: #666;
  float: left;
  padding-left: 15px;
}

.attribute-value {
  float: right;
  padding-right: 15px;
}

.attribute {
  border-top: 1px solid #eee;
  padding: 15px;
}

.form-button {
  background-color: white;
  color: #8a00e5;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  letter-spacing: 0.4px;
  border: 2px solid #8a00e5;
  border-radius: 30px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
  --bs-btn-active-border-color: none;
  --bs-btn-active-bg: #8a00e5;
  --bs-btn-active-color: #8a00e5;
}
// .form-button:hover {
//   background: white;
//   color: #641e8c;
//   border-color: #641e8c;
// }

.label {
  font-size: 10px;
}

.modal-content {
  border-radius: 0px;
  padding: 20px;
}

.form-control {
  border-radius: 0px;
}

.form-select {
  border-radius: 0px;
}

.form-button {
  --bs-btn-hover-border-color: #641e8c;
  --bs-btn-hover-color: #641e8c;
  --bs-btn-hover-bg: white;
  --bs-btn-active-bg: white;
}

.upload-img-container {
  margin: 1rem;
  padding: 1rem;
  width: 50%;
  border: 1px solid black;
}

.new-stock-carousel {
  margin: 1rem;
  padding: 1rem;
  width: 50%;
  border: 1px solid black;

  .carousel-indicators {
    display: none !important;
  }

  .carousel-control-prev {
    left: -8%;
    color: #8a00e5;
    font-size: 1.3rem;
  }

  .carousel-control-next {
    right: -8%;
    color: #8a00e5;
    font-size: 1.3rem;
  }

  .carousel-inner {
    width: 95%;
    height: 11.5rem;
    margin-left: 2.5%;
  }
}

.stock-carousel {
  .carousel-indicators {
    display: none !important;
  }

  .carousel-control-prev {
    left: -13%;
    color: #8a00e5;
    font-size: 1.3rem;
  }

  .carousel-control-next {
    right: -12%;
    color: #8a00e5;
    font-size: 1.3rem;
  }
}

.form-select-btn {
  color: #8a00e5;
  background: white;
  border-width: 2px;
  border-color: #8a00e5;
  border-radius: 0px;
  --bs-btn-hover-color: #8a00e5;
  --bs-btn-hover-border-color: #8a00e5;
  --bs-btn-active-color: #8a00e5;
  --bs-btn-active-bg: white;
}

.form-select-activeBtn {
  color: white !important;
  background-color: #8a00e5 !important;
}

.form-fav-btn {
  border: 0px;
  background: none;
  color: #8a00e5;
  padding: 0px;
  font-size: 20px;
}

.form-fav-btn:hover {
  border: 0px;
  background: none;
  color: #8a00e5;
  padding: 0px;
  font-size: 20px;
  text-decoration: underline;
}

.fav-table-img {
  height: 3rem;
  width: 4rem;
}
