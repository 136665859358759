.trainings-table-container {
  max-height: 23rem;
  overflow-y: auto;
  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      .inprogress-tab {
        text-align: center;
        display: block;
        width: 6.5rem;
        height: 2rem;
        color: #cf9500;
        background-color: #fff9ea;
        border: 1px solid #faeece;
        border-radius: 5px;
      }
      .pending-tab {
        text-align: center;
        display: block;
        width: 6.5rem;
        height: 2rem;
        color: #3a21a1;
        background-color: #f2eeff;
        border: 1px solid #d8cfff;
        border-radius: 5px;
      }
      .completed-tab {
        text-align: center;
        display: block;
        width: 6.5rem;
        height: 2rem;
        color: #2dad00;
        background-color: #ecffe5;
        border: 1px solid #d7f7cb;
        border-radius: 5px;
      }
      .overdue-tab {
        text-align: center;
        display: block;
        width: 6.5rem;
        height: 2rem;
        color: #f02a2a;
        background-color: #ffeaeb;
        border: 1px solid #f8d6d8;
        border-radius: 5px;
      }
      .selp-links {
        color: #8a00e5;
      }
      .selp-links:hover {
        color: #641e8c;
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: #8a00e5;
      color: white;
      border-radius: 10px;
    }
  }
}

.trainings-graph-container {
  .pending-card {
    border-radius: 8px;
    background-color: #f2eeff;
    border: 1px solid #d8cfff;
    cursor: default;
    min-width: 46% !important;
    max-width: 46% !important;
    .pending-text {
      font-size: 17px;
      color: #3a21a1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    .pending-number {
      font-size: 65px;
      color: #3a21a1;
    }
  }

  .inprogress-card {
    border-radius: 8px;
    background-color: #fff9ea;
    border: 1px solid #faeece;
    cursor: default;
    min-width: 46% !important;
    max-width: 46% !important;
    .inprogress-text {
      font-size: 17px;
      color: #fabb1b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    .inprogress-number {
      font-size: 65px;
      color: #fabb1b;
    }
  }

  .completed-card {
    border-radius: 8px;
    background-color: #ecffe5;
    border: 1px solid #d7f7cb;
    cursor: default;
    min-width: 46% !important;
    max-width: 46% !important;
    .completed-text {
      font-size: 17px;
      color: #2fb600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    .completed-number {
      font-size: 65px;
      color: #2fb600;
    }
  }

  .overdue-card {
    border-radius: 8px;
    background-color: #ffeaeb;
    border: 1px solid #f8d6d8;
    cursor: default;
    min-width: 46% !important;
    max-width: 46% !important;
    .overdue-text {
      font-size: 17px;
      color: #f02a2a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    .overdue-number {
      font-size: 65px;
      color: #f02a2a;
    }
  }
}

.trainings-carousel {
  .carousel-indicators {
    position: absolute !important;
    top: 24.5rem;
  }
}
