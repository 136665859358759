.chart {
  display: flex;
  justify-content: center;
}

#container {
  margin: 1em auto;
}

#container h4 {
  text-transform: none;
  font-size: 11px;
  font-weight: bold;
}

#container p {
  font-size: 12px;
  line-height: 11px;
  font-weight: bold;
  color: #7437a2;
  padding: 5px 0;
}

#container p + p {
  font-size: 12px;
  line-height: 12px;
  color: #755dd9;
  font-weight: 700;
}

.highcharts-credits {
  display: none;
}

.highcharts-label {
  opacity: 1 !important;
}

.titleDiv {
  border-radius: 2px;
}

.title {
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  padding: 2px 5px;
}

.org-list-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  // font-weight: bold;
  --s: 0.1em;
  --c: #8900e4;
  color: rgba(0, 0, 0, 0);
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
}
.list-org {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.breadcrumb-btn:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.MuiTreeItem-root {
  & > .MuiTreeItem-content {
    transition: background-color 0.3s ease;

    &.Mui-selected,
    &[data-is-root-selected="true"] {
      background-color: rgba(138, 0, 229, 0.078);

      // Nested styles for the label container
      .org-tree-root-node {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -8px;
          top: 0;
          height: 100%;
          width: 3px;
          // background: #8a00e5;
          border-radius: 3px;
        }
      }
    }
  }

  // Double selector specificity for override
  &.Mui-selected > .MuiTreeItem-content,
  &[data-is-root-selected="true"] > .MuiTreeItem-content {
    background-color: rgba(138, 0, 229, 0.08) !important;
  }
}

.MuiTreeItem-content.Mui-selected {
  background-color: #f5e6ff !important;
}

.MuiTreeItem-content {
  border-bottom: 1px solid #c0c0c0 !important;
}

.MuiCollapse-root.MuiCollapse-vertical.MuiTreeItem-group {
  border-left: 2px solid #8900e5;
}

.org-table {
  max-height: 49.3rem;
  overflow-y: auto;
  .table {
    display: block;
    empty-cells: show;
    --bs-table-striped-bg: none !important;
    width: 100%;

    thead {
      background: #ffff !important;
      color: black;
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 0;
    }

    tbody {
      display: block;
      position: relative;
      width: 100%;
      overflow-y: hidden;
      min-height: 38rem;
      text-align: center;

      tr:nth-child(even) {
        background-color: #f8f8f8;
      }
    }

    .more-user-btn {
      border: none;
      background: none;
      color: #8a00e5;
      font-size: small;
    }

    .more-user-btn:hover {
      text-decoration: underline;
    }

    tr {
      width: 100%;
      display: flex;
      border: none;
      height: 3.4rem;
    }

    th {
      display: block;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    th:first-child {
      text-align: left;
      padding-left: 1rem;
      width: 20%;
    }

    th:nth-child(2) {
      text-align: left;
      width: 63%;
      background-color: #ffff;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    th:last-child {
      text-align: left;
      width: 17%;
    }

    td {
      vertical-align: middle;
      border: none !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    td:first-child {
      text-align: left;
      width: 20%;
      padding-top: 1rem;
    }

    td:first-child:hover:after,
    td:nth-child(2):hover:after {
      content: attr(title);
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      z-index: 1;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    td:first-child:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    td:nth-child(2) {
      text-align: left;
      width: 63%;
      padding-top: 1rem;
    }

    td:last-child {
      width: 17%;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-top: none !important;
    }

    .td:last-child > div > td:first-child {
      padding-top: none !important;
    }

    .process-table-name {
      color: black !important;
      cursor: pointer !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
