.preview-page {
  .custom-font-size {
    font-size: 17px;
    font-weight: 400;
  }

  .custom-desc-color {
    color: #5f5f5f;
    font-size: 17px;
  }

  .custom-link {
    color: #8a00e5;
  }
  .custom-btn {
    cursor: pointer;
    color: #8a00e5;
  }
}
