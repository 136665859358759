.upload-container {
  cursor: copy;
  height: 2.5rem;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);

  transition: 0.2s box-shadow, 0.2s background-color;
  box-shadow: 0 10px 40px -30px rgb(0 0 0 / 20%);

  &.over {
    background-color: #4d217a;
    color: white;
    box-shadow: 0 20px 60px -30px rgba(#2669ff, 1);
  }

  h2 {
    transition: 0.2s color;
    text-align: center;
  }

  p {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0.8rem;
    opacity: 75%;
  }
}

.blob-container {
  p {
    margin-top: 1.5rem;
    margin-bottom: 0.3rem;
  }

  img {
    max-height: 5rem;
  }
}