#root {
  $company-card-width: 25%;
  $fings-card-width: 25%;
  $card-width: 25%;
  overflow-x: hidden;
}

.newscomp {
  max-width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.news-header {
  width: 100%;
  .sec-title {
    color: #1e1e1e;
  }
}
.text-dark {
  color: #8a00e5 !important;
}
.spinner-grow {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.newscomp-carousel {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    left: 100% !important;
  }

  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    right: 100% !important;
    left: auto !important;
  }
  max-width: 100%;
  .card {
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    width: var(--card-width) !important;
    // width: 50%;
    height: 24rem;

    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      font-weight: bold;
      --s: 0.1em;
      --c: #641e8c;
      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }
    .card-body {
      position: relative;
      text-align: left;
    }
    .card-text {
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .yammer-card-text {
      -webkit-line-clamp: 3 !important;
    }

    .blockquote-footer {
      overflow: hidden;
      display: inline-flex;
      flex-direction: row;
      position: absolute;
      top: 11rem;
      width: 87%;

      .card-author {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .yammer-card-arrow {
    position: absolute !important;
    top: 0.8rem;
    margin-left: 0 !important;
    right: 1rem;
  }
  .card-arrow {
    margin-left: 20%;

    color: #641e8c;
    transition: transform 0.3s ease;
  }
  .card:hover .card-arrow {
    color: #641e8c;
    transform: scale(1.5);
  }
  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
  .nav-link {
    color: black;
  }
  .img-hover-zoom {
    height: 180px;
  }
  .carousel-inner {
    position: relative;
    max-width: 100%;
    // margin-left: 9%;
    overflow: hidden;
  }
  .carousel-indicators {
    margin-bottom: -32px;
  }
}

.company-newscomp-carousel {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    left: 100% !important;
  }

  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    right: 100% !important;
    left: auto !important;
  }
  max-width: 100%;
  .card {
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    width: var(--company-card-width) !important;
    // width: 50%;
    height: 24rem;

    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      font-weight: bold;
      --s: 0.1em;
      --c: #641e8c;
      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }
    .card-body {
      position: relative;
      text-align: left;
    }
    .card-text {
      color: black;

      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .yammer-card-text {
      -webkit-line-clamp: 3 !important;
    }

    .blockquote-footer {
      overflow: hidden;
      display: inline-flex;
      flex-direction: row;
      position: absolute;
      top: 10rem;
      width: 87%;

      .card-author {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .yammer-card-arrow {
    position: absolute !important;
    top: 0.8rem;
    margin-left: 0 !important;
    right: 1rem;
  }
  .card-arrow {
    margin-left: 20%;

    color: #641e8c;
    transition: transform 0.3s ease;
  }
  .card:hover .card-arrow {
    color: #641e8c;
    transform: scale(1.5);
  }
  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
  .nav-link {
    color: black;
  }
  .img-hover-zoom {
    height: 180px;
  }
  .carousel-inner {
    position: relative;
    max-width: 100%;
    // margin-left: 9%;
    overflow: hidden;
  }
  .carousel-indicators {
    margin-bottom: -32px;
  }
}

.fings-newscomp-carousel {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    left: 100% !important;
  }

  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    right: 100% !important;
    left: auto !important;
  }
  max-width: 100%;
  .card {
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    width: var(--fings-card-width) !important;
    // width: 50%;
    height: 24rem;

    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      font-weight: bold;
      --s: 0.1em;
      --c: #641e8c;
      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }
    .card-body {
      position: relative;
      text-align: left;
    }
    .card-text {
      color: black;

      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .yammer-card-text {
      -webkit-line-clamp: 3 !important;
    }

    .blockquote-footer {
      overflow: hidden;
      display: inline-flex;
      flex-direction: row;
      position: absolute;
      top: 10rem;
      width: 87%;

      .card-author {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .yammer-card-arrow {
    position: absolute !important;
    top: 0.8rem;
    margin-left: 0 !important;
    right: 1rem;
  }
  .card-arrow {
    margin-left: 20%;

    color: #641e8c;
    transition: transform 0.3s ease;
  }
  .card:hover .card-arrow {
    color: #641e8c;
    transform: scale(1.5);
  }
  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
  .nav-link {
    color: black;
  }
  .img-hover-zoom {
    height: 180px;
  }
  .carousel-inner {
    position: relative;
    max-width: 100%;
    // margin-left: 9%;
    overflow: hidden;
  }
  .carousel-indicators {
    margin-bottom: -32px;
  }
}

.community-newscomp-carousel {
  .carousel-control-next {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    left: 100% !important;
  }

  .carousel-control-prev {
    color: #7e24dc;
    font-size: 2rem;
    width: 2rem;
    right: 100% !important;
    left: auto !important;
  }
  max-width: 100%;
  .card {
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    transition: 0.6s;
    width: 25% !important;
    // width: 50%;
    height: 24rem;

    .img-hover-zoom {
      z-index: 1;
      overflow: hidden;
    }

    .img-hover-zoom .card-img-bottom {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      font-weight: bold;
      --s: 0.1em;
      --c: #641e8c;
      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
    }
    .card-body {
      position: relative;
      text-align: left;
    }
    .card-text {
      color: black;

      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .yammer-card-text {
      -webkit-line-clamp: 3 !important;
    }

    .blockquote-footer {
      overflow: hidden;
      display: inline-flex;
      flex-direction: row;
      position: absolute;
      top: 11rem;
      width: 87%;

      .card-author {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .yammer-card-arrow {
    position: absolute !important;
    top: 0.8rem;
    margin-left: 0 !important;
    right: 1rem;
  }
  .card-arrow {
    margin-left: 20%;

    color: #641e8c;
    transition: transform 0.3s ease;
  }
  .card:hover .card-arrow {
    color: #641e8c;
    transform: scale(1.5);
  }
  .card:hover {
    --_p: 100%;
    --bs-card-border-color: black;
  }
  .nav-link {
    color: black;
  }
  .img-hover-zoom {
    height: 180px;
  }
  .carousel-inner {
    position: relative;
    max-width: 100%;
    // margin-left: 9%;
    overflow: hidden;
  }
  .carousel-indicators {
    margin-bottom: -32px;
  }
}
