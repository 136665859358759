.custom-node {
  .node-up {
    background-color: #f4e4ff;
    border-radius: 3px;
  }
  .nameText {
    color: #8a00e5;
    font-weight: bold;
  }

  .react-flow__node-default,
  .react-flow__node-group,
  .react-flow__node-input,
  .react-flow__node-output {
    border: 1px solid rgba(100, 30, 140, 1) !important;
    padding: 0 !important;
    border-radius: 4px !important;
    text-align: unset !important;

    .node-up {
      background: #e6e6e6;
    }
    .node-down {
      border-radius: 3px;
      background: #ffffff;
    }

    .layerText {
      padding: 10px 8px 0 6px;
      font-size: 10px;
      color: white;
    }
    .nameText {
      padding: 8px 8px 9px 6px;
      font-size: 13px;
      line-height: 12px;
      color: white;
      width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .descText {
      font-size: 10px;
      padding: 8px 8px 25px 6px;
      line-height: 11px;
      color: #000;
      letter-spacing: 0.2px;
      width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .processDescText {
      height: 60px;
      padding: 3px 5px 3px 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
}
