.spline-comp {
  height: 100vh;
  width: 100%;
  position: relative;
}
.internal-page {
  height: 100vh;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2rem;
  overflow-y: scroll;
}
.main-page {
  width: 91%;
}

.internal-tools-page {
  height: 100vh;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2rem;
}
.close-button {
  color: #8a00e5;
  background: none;
  margin-left: 2rem;
  border: 0px;
  font-size: 20px;
  font-weight: bold;
}

.close-button:hover {
  color: #641e8c;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.main-landscape-loader {
  height: 100vh;
  .internal-loader {
    height: 100%;
  }
}

.scroll-text {
  right: 0;
  color: white;
  background-color: #641e8c;
}
