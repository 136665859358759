.notification-alert {
  position: fixed;
  max-width: 700px;
  top: 10px;
  right: 10px;
  z-index: 1050;
  min-width: 300px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  // animation: slide-in 0.3s ease-out, fade-out 0.3s ease-in 2.7s forwards;
  animation: slide-in 0.5s ease forwards;

  .notification-content {
    margin: auto auto;
    align-items: center;
    justify-content: space-between;
    font-family: "Siemens Sans", Arial, sans-serif !important;
    width: 94%;
  }

  .close-btn {
    position: absolute;
    right: 10px;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  /* Slide-in animation */
  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  /* Fade-out animation */
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.alert-danger {
  background-color: #da1e28;
  color: #fff;
  border-color: #da1e28;
}
.alert-success {
  background-color: #007942;
  color: #fff;
  border-color: #007942;
}
.alert-warning {
  background-color: #e2d000;
  color: #000;
  border-color: #e2d000;
}
.alert-info {
  background-color: #0057c6;
  color: #fff;
  border-color: #0057c6;
}
