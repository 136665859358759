.group-modal-container {
  .modal-body {
    max-width: 100%;
    height: max-content;
  }
  .custom-loader {
    max-width: 100%;
    height: 13vh;
  }
  .input-fields {
    padding: 0.375rem 0.75rem;
  }
}
