.process-details {
  width: 80% !important;

  .process-btn {
    color: black !important;
    cursor: pointer !important;
    &:hover {
      text-decoration: none !important;
      text-underline-offset: none !important;
    }
  }
}
