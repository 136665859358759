.appMap-page {
  margin-top: 5px;
  text-align: left;
}

.mainDiv {
  // border: 1px solid #cfcfcf;
  border-top: 1px solid #cfcfcf;
  width: 100%;
  height: 100%;
  display: flex;
}

.leftSide {
  width: 20%;
  border-right: 1px solid #cfcfcf;

  select {
    option {
      color: #8a00e5;
    }

    option:hover {
      color: red !important;
    }
  }

  .appHead {
    padding: 0px 35px 0px 20px;
    color: #1b1734;
    font-size: 20px;
    letter-spacing: 0.2;
    font-weight: 700;
    line-height: 45px;
  }

  form {
    color: #1b1734;
    display: flex;
    padding: 2px;
    border: 1px solid #cfcfcf;
    border-radius: 2px;
    padding-left: 20px;
  }
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  input[type="search"]::placeholder {
    color: rgba(33, 37, 41, 0.6);
  }
  input[type="search"]:focus {
    box-shadow: 0 0 2px 0 rgba(77, 33, 122, 0.7);
    border-color: rgba(77, 33, 122, 0.7);
    outline: none;
  }
  form.search-app {
    border: none;
  }
  input.search-app {
    width: 93%;
    border: 1px solid #cfcfcf;
    display: block;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }

  .appList {
    height: auto;
    max-height: 460px;
    overflow-y: scroll;
    margin-bottom: 10px;

    .appTag {
      font-size: 12px;
      line-height: 24px;
      color: #1b1734;
      padding: 6px 0 6px 5px;
      margin: 0px 20px;
      border-radius: 0;
      cursor: pointer;

      &:hover {
        color: #8a00e5;
        font-weight: bold;
      }
      &.act {
        color: #8a00e5;
        font-weight: bold;
        border-left: 2px solid #8a00e5;
        border-radius: 0;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(217, 217, 217, 1);
    border-radius: 10px;
  }

  div:hover::-webkit-scrollbar-thumb {
    background: gray;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: gray !important;
  }
}

.rightSide {
  width: 80%;

  .titDiv {
    padding: 15px 20px 0 15px;
    .innerDiv {
      // height: 160px;
      // border: 1px solid #cfcfcf;
    }
  }

  .section {
    padding: 12px 20px 24px 21px;
    .innerSec {
      min-height: 420px;
      // border: 1px solid #cfcfcf;
    }
  }
}

.details {
  .titLabel {
    margin: 0;
    padding: 0px 0 0 7px;
    font-size: 18px;
    color: #1b1734;
  }
  .titName {
    font-weight: 700;
    padding-left: 5px;
    color: #8a00e5;
  }

  .titdesc {
    font-size: 15px;
    padding: 5px 28px 0 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    height: 49px;
  }
  .img {
    margin: 0 0 0 10px;
    cursor: pointer;
  }

  .app-info {
    margin-top: 11px;
    padding: 0 30px 15px 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .secDiv {
      flex: 1;
      padding: 5px 5px 8px 10px;
      border: 1px solid #cfcfcf;
    }
    .addInfo {
      margin: 0 5px;
      display: flex;
    }
    .secName {
      margin: 0;
      font-size: 12px;
      color: #1b1734;

      &.name {
        font-weight: 700;
      }
      &.val {
        display: flex;
        font-weight: 400;
      }
      &.flex-col {
        flex-direction: column;
      }
      &.trunc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.txt {
          width: 200px;

          @media screen and (max-width: 1366px) {
            width: 140px;
          }

          @media screen and (max-width: 1260px) {
            width: 120px;
          }

          @media screen and (max-width: 1024px) {
            width: 90px;
          }

          @media screen and (max-width: 768px) {
            width: 50px;
          }
        }
      }
    }
  }
}

.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100%;
  margin-top: 10px;
  border: 1px solid #dee2e6;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}
// .react-flow {
//   overflow: scroll !important;
// }
.react-flow__attribution {
  display: none;
}

.react-flow__handle {
  width: 2px !important;
  height: 2px !important;
}

.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  border: 1px solid rgba(100, 30, 140, 1) !important;
  padding: 0 !important;
  border-radius: 4px !important;
  text-align: unset !important;

  .node-up {
    background: #e6e6e6;
  }
  .node-down {
    border-radius: 3px;
    background: #ffffff;
  }

  .layerText {
    padding: 10px 8px 0 6px;
    font-size: 10px;
    color: white;
  }
  .nameText {
    padding: 8px 8px 9px 6px;
    font-size: 13px;
    line-height: 12px;
    color: white;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .descText {
    font-size: 10px;
    padding: 8px 8px 25px 6px;
    line-height: 11px;
    color: #000;
    letter-spacing: 0.2px;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .processDescText {
    height: 60px;
    padding: 3px 5px 3px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
.capitalize {
  text-transform: capitalize;
}

.tabs-section {
  .tools-tab-btn {
    color: black;
    font-weight: bold;
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    --bs-btn-hover-color: #8a00e5;
    --bs-btn-hover-border-color: #8a00e5;
    --bs-btn-active-bg: white;
  }
  .actTab {
    color: #8a00e5;
    border-bottom: 3px solid #8a00e5;
    // font-weight: 800;
  }
}
