.layers-flow {
  height: 40rem;
  padding-bottom: 1rem;

  .flow-canvas {
    height: 100% !important;
    width: 100% !important;

    background-color: #f8f8f8;
  }
}

.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  border: 1px solid #dee2e6;
  height: 40rem;

  .flow-canvas {
    height: 100% !important;
    width: 100% !important;

    background-color: #f8f8f8;
  }
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}

.react-flow__attribution {
  display: none;
}

.react-flow__handle {
  width: 2px !important;
  height: 2px !important;
}
