.overview-container {
  max-width: 100%;
}
.trainings-data {
  height: 31.25rem !important;
}

.overview-comp {
  min-height: 31rem !important;
  background-color: rgb(246, 244, 255);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  .trainings-dashboard {
    .main-comp {
      margin-top: 0 !important;
    }
    .title-container {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .title {
        margin-top: 0 !important;
        font-size: 0 !important;
      }
    }
    .trainings-carousel {
      margin-top: 0 !important;
      .carousel-inner {
        border: 1px solid rgba(0, 0, 0, 0.176);
      }
      .carousel-item {
        height: 24.25rem !important;
      }
      .carousel-indicators {
        position: absolute !important;
        top: 24.9rem;
      }
    }
  }
}

.overview {
  .heading {
    margin-top: 15%;
  }
  .purple {
    color: rgb(138, 0, 229);
  }
}
.video-container {
  flex: 1 1 50%;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.scrollable-content {
  max-height: 50vh;
  overflow-y: auto;
}
.content-container {
  flex: 1 1 50%;
}
@media (min-width: 576px) {
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl {
    max-width: 2000px;
  }
}
