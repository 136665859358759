.learning-admin-loader {
  justify-content: center;
  align-items: center;
  height: 445px;
  width: 100%;
}
.responsive-container {
  .back-icon {
    font-weight: bold;
  }
  .main-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 2rem;
  }
}

.disabled-icon {
  pointer-events: none;
  opacity: 0.25;
  cursor: not-allowed !important;
}

.source-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-cell {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.owners-cell {
  width: 95%;
}
.actions-cell {
  .edit-icon {
    cursor: pointer;
    margin-right: 10px;
  }

  .delete-icon {
    cursor: pointer;
  }
}
.search-input {
  width: 19rem;
  margin-bottom: 13px;
  padding: 7px;
  margin-top: 5px;
}
.search-input:focus {
  outline: none;
  box-shadow: none;
}

.learning-table {
  min-height: 70vh;
}

.rating_none {
  color: grey;
  height: 25px;
  width: 30px;
}
.rating_full {
  color: #e7e73f;
  height: 25px;
  width: 30px;
}
