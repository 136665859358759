.owner-profile-container {
  display: flex;
  align-items: center;
}
.owner-profile {
  cursor: pointer;

  .profile-img {
    height: 2rem;
    width: 2rem;
    border: 1.5px solid black;
    border-radius: 7rem;
    object-fit: contain;
  }
  div {
    display: inline-flex;
    font-size: 10px;
  }
}
