.main-manage {
  .app-table-name {
    color: black !important;
    cursor: pointer !important;
    &:hover {
      text-decoration: underline !important;
      text-underline-offset: 3px !important;
    }
  }

  .app-preview {
    .preview-link {
      a {
        color: #8a00e5;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
    .preview-flow {
      height: 400px;
      background-color: #f8f8f8;
    }
  }

  .add-app-form {
    form {
      .main-container {
        max-width: 100%;
      }
      .app-new-btn {
        color: #8a00e5;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }
  .main-app {
    .app-title {
      span {
        h6 {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          padding-bottom: 0.2rem;
          padding-left: 0.3rem;
        }
      }
    }
    .layers-section {
      height: 40rem;
      //   width: 100%;
      .list-section {
        background-color: #f8f8f8;

        .select-tool {
          display: block;
          width: 86%;
        }

        .tools-list {
          height: 33rem;
          overflow-y: scroll;
        }
        .select-layer {
          .tool-details {
            width: 86%;
          }
        }

        .app-search-box {
          width: 86%;
          form {
            color: #1b1734;
            display: flex;
            padding: 2px;
            border: 1px solid #cfcfcf;
            border-radius: 2px;
            padding-left: 0px;
          }
          input.search-app {
            width: 99%;
            border: 1px solid #cfcfcf;
            display: block;
            padding: 9px 4px 9px 40px;
            background: transparent
              url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
              no-repeat 13px center;
          }
        }

        .add-tool-btn {
          width: 86% !important;
        }
        width: 86%;

        .tool-desc {
          width: 86%;
          input {
            height: 1rem;
          }
        }
        .buttons-section {
          width: 86%;
          font-weight: bold;
          .btn-1 {
            background: transparent;
            color: #8c7500;
            border: 1.5px solid #8c7500;
            border-radius: 3rem;
            &:hover {
              background-color: #8c7500;
              color: white;
            }
          }
          .act-btn-1 {
            background-color: #8c7500;
            color: white;
          }

          .btn-2 {
            background: transparent;
            color: #006c3b;
            border: 1.5px solid #006c3b;
            border-radius: 3rem;
            &:hover {
              background-color: #006c3b;
              color: white;
            }
          }
          .act-btn-2 {
            background-color: #006c3b;
            color: white;
          }
          .btn-3 {
            background: transparent;
            color: #00328c;
            border: 1.5px solid #00328c;
            border-radius: 3rem;
            &:hover {
              background-color: #00328c;
              color: white;
            }
          }
          .act-btn-3 {
            background-color: #00328c;
            color: white;
          }
        }

        .list-comp {
          width: 86%;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            padding-bottom: 0.2rem;
            padding-left: 0.3rem;
          }
        }

        form {
          color: #1b1734;
          display: flex;
          padding: 2px;
          border: 1px solid #cfcfcf;
          border-radius: 2px;
          padding-left: 20px;
        }
        input[type="search"] {
          border: none;
          background: transparent;
          margin: 0;
          padding: 7px 8px;
          font-size: 14px;
          color: inherit;
          border: 1px solid transparent;
          border-radius: inherit;
        }
        input[type="search"]::placeholder {
          color: rgba(33, 37, 41, 0.6);
        }
        input[type="search"]:focus {
          box-shadow: 0 0 2px 0 #8a00e5;
          border-color: #8a00e5;
          outline: none;
        }
        form.search-app {
          border: none;
        }
        input.search-app {
          width: 93%;
          border: 1px solid #cfcfcf;
          display: block;
          padding: 9px 4px 9px 40px;
          background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat 13px center;
        }
        ::-webkit-scrollbar {
          width: 5px;
        }

        ::-webkit-scrollbar-track {
          background: #f8f8f8;
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(217, 217, 217, 1);
          border-radius: 10px;
        }

        div:hover::-webkit-scrollbar-thumb {
          background: gray;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: gray !important;
        }
      }
      .flow-canvas {
        .flow-canvas {
          height: 100%;
        }
        //   width: 75%;
        background-color: #f8f8f8;
      }
    }
  }
}

.app-new-btn {
  color: #8a00e5;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
