.add-and-edit-process {
  .input-width {
    width: 50.5%;
  }

  .group-dropdown {
    .custom-group {
      padding: 2px 8px !important;
      border-radius: 7px !important;

      .group-custom-btn {
        margin-left: 8px !important;
        border: none !important;
        cursor: pointer !important;
        font-weight: bold !important;
        background-color: transparent !important ;
      }
    }
  }
}
