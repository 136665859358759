.main-container {
  padding: 0.7rem;
  border: 1.5px solid #969696;
  border-radius: 4px !important;
  margin: 0;
  max-width: 100%;

  .upload_image_container {
    .upload-container {
      cursor: copy;
      height: 6.8rem;
      margin-left: 3rem;
      position: relative;
      border: none;
      transition: 0.2s box-shadow, 0.2s background-color;
      box-shadow: 0 10px 40px -30px rgb(0 0 0 / 20%);
      min-height: 7rem;

      &.over {
        background-color: #4d217a;
        color: white;
        box-shadow: 0 20px 60px -30px rgba(#2669ff, 1);
      }

      .upload-container {
        border: none;
      }

      h2 {
        transition: 0.2s color;
        text-align: center;
      }

      p {
        margin-bottom: 0px;
        margin-top: 96px;
        margin-left: 0.8rem;
        opacity: 75%;
        margin-top: 0.3rem;
        color: #8900e4;
      }
    }

    .blob-container {
      img {
        max-height: 5rem;
        margin-top: 1rem;
        margin-left: 1rem;
      }
    }
  }

  .upload-text {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .para {
    color: #969696;
  }
}

.main-container:hover {
  background-color: #fff;
  border: 1.5px solid #641e8c !important;
}
