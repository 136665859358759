.ultimoCalendar {
  font-size: 11pt;

  .ultimoText {
    display: flex;
    color: #8a00e5;
    justify-content: space-between;
    align-items: center;
    font-size: small;
  }

  .popupCalendar {
    .ultimos {
      color: #8a00e5;
      font-size: smaller;
    }

    .react-datepicker__input-container {
      cursor: pointer;
      transition: 0.2s ease;
    }

    .react-datepicker__input-container:hover {
      color: #8a00e5;
    }

    .react-datepicker {
      border-radius: 5px;
    }

    .react-datepicker__header {
      background-color: white;
      border-bottom: 0;
    }

    .react-datepicker__month-container {
      float: none;
    }

    .react-datepicker__month {
      margin-top: 0;
      margin-bottom: 0;
    }

    .react-datepicker__children-container {
      width: 100%;
      margin: 0;
      padding: 10px;
    }

    .react-datepicker__day-name {
      width: 3.5em;
      height: 2em;
      font-weight: bold;
      margin-top: 1em;
      line-height: normal;
      font-size: 11pt;
      align-content: center;
    }

    .react-datepicker__day {
      font-weight: normal;
      width: 3.5em;
      height: 3.5em;
      line-height: normal;
      align-content: center;
      font-size: 11pt;
      cursor: default;
    }

    .react-datepicker__day:hover {
      background-color: #ffffff;
    }

    .react-datepicker__day--today {
      border: 2px solid #8a00e5 !important;
      border-radius: 5px;
      color: black;
      background-color: transparent !important;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }

    .react-datepicker__day--keyboard-selected:hover {
      background-color: #ffffff;
    }

    .react-datepicker__navigation--next {
      content: url("../../../../assets/images/arrowRight.svg");
      height: 20px;
      right: 100px;
      top: 7px;
    }

    .react-datepicker__navigation--previous {
      content: url("../../../../assets/images/arrowLeft.svg");
      height: 20px;
      left: 100px;
      top: 7px;
    }
  }
}
