table {
  width: 100%;

  thead {
    background: #ffff !important;
    color: black;
    border-bottom: 1px solid #3f3c3c;
  }

  tbody {
    text-align: center;

    tr:hover {
      background-color: #f7eaff !important;
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: #f8f8f8;
    }
  }
  th,
  td {
    padding: 0.5rem;
    text-align: center;
    position: relative;

    &:first-child {
      text-align: left;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:last-child {
      text-align: left;
    }
  }

  tr {
    border: none;
  }

  td {
    vertical-align: left;
    border: none !important;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-child(4) .table-owner {
      border: 1.5px solid black;
      background: #5f5f5f;
      border-radius: 2.2rem;
    }
  }
}

.btn {
  background-color: none;
}
