.learning-container {
  .card {
    height: 23.5rem !important;
    max-width: 33.33%;
  }

  .session-details {
    font-size: 12px;
    max-width: 92% !important;
  }
  .description-section {
    -webkit-line-clamp: 3 !important;
    min-height: 4rem !important;
    max-height: 5rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

.course-details-container {
  padding-top: 6px;
  padding-bottom: 5px;
}
.carousel-loader {
  max-width: 100%;
  height: 24rem;
}
