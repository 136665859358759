.main-page-margin {
  .tool-page {
    overflow-x: hidden;
    overflow-y: hidden;

    .main-title {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .tool-form {
      .form-text-field {
        overflow-y: hidden;
      }

      .form-text-field:hover {
        background-color: #fff;
        border-color: #8a00e5;
      }

      .form-text-field:focus {
        border-color: #8a00e5 !important;
        box-shadow: none !important;
      }

      .user-btn {
        color: white;
        background-color: #8a00e5;
        border-radius: 8px;
        border: 1px solid #8a00e5;
        // background: none;
        border: 2px solid #8a00e5;
      }

      .user-btn:hover {
        border-color: #641e8c;
        background-color: white;
        color: #641e8c;
      }

      .user-btn-cancel {
        float: right;
        color: #8a00e5;
        font-weight: bold;
        background: #f7f7f7;
        border: 2px solid #8a00e5;
        border-radius: 20px;
        height: 2.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .user-btn-cancel :hover {
        color: white !important;
        border: 1px solid #8a00e5 !important;
        background-color: #8a00e5 !important;
      }

      // .btn:first-child:hover {
      //   color: white !important;
      //   border: 2px solid #fdf9ff !important;
      //   background: #8a00e5 !important;
      // }
    }
  }

  .user-btn {
    color: #8a00e5;
    background-color: white;
    border-radius: 3rem;
    border: 2px solid #8a00e5;
  }

  .user-btn:hover {
    border-color: #641e8c;
    background-color: white;
    color: #641e8c;
  }

  .user-btn-cancel {
    color: #8a00e5;
    border-radius: 10px;
    border: 2px solid #8a00e5;
    background-color: #f8f8f8;
  }

  // .btn:first-child:hover {
  //   color: white !important;
  //   border: 2px solid #fdf9ff !important;
  //   background: #8a00e5 !important;
  // }

  .user-btn-cancel :hover {
    color: white !important;
    border: 2px solid #8a00e5 !important;
    background: #8a00e5 !important;
  }

  .tool-table {
    overflow-y: hidden;

    .user-table {
      padding-top: 1rem;

      .table {
        display: block;
        empty-cells: show;
        --bs-table-striped-bg: none !important;

        thead {
          background: #ffff !important;
          color: black;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 0;
        }

        tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: hidden;
          min-height: 38rem;
          text-align: center;

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        .more-user-btn {
          border: none;
          background: none;
          color: #8a00e5;
          font-size: small;
        }

        .more-user-btn:hover {
          text-decoration: underline;
        }

        tr {
          width: 100%;
          display: flex;
          border: none;
          height: 3.4rem;
        }

        th {
          display: block;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:first-child {
          text-align: left;
          padding-left: 1rem;
          width: 15%;
        }

        th:nth-child(2) {
          text-align: left;
          width: 25%;
          background-color: #ffff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:nth-child(3) {
          text-align: left;
          width: 20%;
        }

        th:nth-child(4) {
          text-align: left;
          width: 29%;
        }

        th:last-child {
          text-align: left;
          width: 12%;
        }

        td {
          vertical-align: left;
          border: none !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 1rem;
          text-align: left;
          width: 15%;
        }

        td:nth-child(2) {
          text-align: left;
          width: 25%;
        }

        td:nth-child(3) {
          width: 20%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        td:nth-child(4) {
          text-align: left;
          width: 29%;
        }

        td:last-child {
          text-align: left;
          width: 11%;
        }
      }
    }
  }
}

.css-wmy1p7-ReactDropdownSelect:hover {
  border-color: #8a00e5 !important;
}

// .css-wmy1p7-ReactDropdownSelect:focus-within {
//   box-shadow: none !important;
//   border-color: #8a00e5 !important;
// }

.css-v352tl-ReactDropdownSelect:focus,
.css-v352tl-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
  outline: 0;
}

.css-v352tl-ReactDropdownSelect:focus {
  box-shadow: none !important;
}

.user-modal {
  border-radius: 12px;

  .modal-content {
    border-radius: 12px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .user-modal-user-list {
    border: none;
    padding-left: 0;

    .user-name {
      font-weight: 500;
    }
  }
}

.delete-modal {
  border-radius: 10px;

  .modal-content {
    border-radius: 12px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
