.grid-container {
  .owner {
    cursor: pointer;
    .owner-img {
      height: 2rem;
      width: 2rem;
      border: 1.5px solid black;
      border-radius: 7rem;
      object-fit: contain;
    }
  }

  .process-cards {
    border-style: dashed;
    border-width: 2.3px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: white;
    border-color: #b3b3b3;
    cursor: pointer;

    .owner-profiles {
      height: 2rem !important;
    }

    @media (max-width: 1525px) {
      min-height: 180px;
    }
    @media (max-width: 1450px) {
      min-height: 215px;
    }
    @media (max-width: 1200px) {
      min-height: 200px;
    }

    @media (max-width: 992px) {
      min-height: 200px;
    }

    @media (max-width: 768px) {
      min-height: 120px;
    }

    @media (max-width: 576px) {
      min-height: 110px;
    }

    .process-title-content {
      max-width: 70%;
    }

    .process-name {
      color: black !important;
    }

    .process-name:hover {
      text-decoration: none !important;
    }
    .process-title {
      font-size: 20px;
      font-weight: 600;
      max-height: 2em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;

      .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -100px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }

    .org-container {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      height: 1.5rem;
    }
    .tooltip-1 {
      #tooltip-title > .tooltip-inner {
        background-color: #cc2020 !important;
        color: #000;
        border: 1px solid #062e56;
      }
    }

    .org-title {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.2rem;
    }

    .comma-separator {
      margin: 0 0.3rem;
      white-space: nowrap;
    }

    .highlighted {
      .process-cards {
        background-color: black;
      }
    }

    .not-highlighted {
      background-color: white;
    }
    .org-title {
      color: #8a00e5;
      font-size: 15px;
      font-weight: 300;

      .org-title-link {
        color: #8a00e5 !important;
      }
    }

    .process-desc {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      min-height: 3.1rem;
      max-height: 3.5rem;
    }
    .members-count {
      margin-top: auto;
      font-size: 13px;
      color: #8a00e5;
      margin-top: 3px;
    }
    .members-count:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .process-cards:hover {
    border-color: #8a00e5;
  }

  .highlighted {
    .process-cards {
      background-color: black;
    }
  }

  .no-data-check {
    padding-top: 7rem;
  }
}

.link-item {
  height: 30px !important;
  border: 1px solid #8a00e5;
  border-radius: 24px;
  color: #8a00e5;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 9px;
  margin-bottom: 5px;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: #8a00e5 !important;
    background-color: white !important;
    border-color: #8a00e5 !important;
  }
  &:active {
    color: #8a00e5 !important;
    background-color: white !important;
    border-color: #8a00e5 !important;
  }
  &:disabled {
    color: #ada9c2 !important;
    background-color: white !important;
    border-color: #ada9c2 !important;
  }
}

.empty-tags-fallback {
  height: 45px;
  background-color: transparent;
}
