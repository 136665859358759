.favorite {
  max-width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.folder-modal {
  width: 100%;
  margin-right: 15px;
}

.modal-fav-add {
  float: right;
}

.favorite-small-carousel {
  width: 33% !important;
}

.favorite-carousel {
  max-width: 100%;
}

.fav-navbar .container {
  max-width: 84%;
  .navbar-brand {
    font-size: 22px;
    font-weight: 600;
  }
}

.favorite-carousel .carousel-indicators {
  position: absolute;
  top: 23rem;
  color: #8a00e5 !important;
}

.favorite-carousel .carousel-indicators [data-bs-target] {
  background-color: #8a00e5;
}

.favorite-carousel {
  .carousel-control-next {
    color: #8a00e5;
    font-size: 2rem;
    width: 2rem;
    left: 100%;
  }
  .carousel-control-prev {
    color: #8a00e5;
    font-size: 2rem;
    width: 2rem;
    right: 100%;
    left: auto !important;
  }
  .card {
    cursor: pointer;
    --bs-card-spacer-y: 0rem;
    --bs-card-spacer-x: 0rem;
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    height: 8.8rem;
    width: 17%;
  }
  .img-hover-zoom {
    z-index: 1;
    overflow: hidden;
    height: 100%;
  }

  .img-hover-zoom .card-img-bottom {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  .card-img-overlay {
    z-index: 2;
  }

  .card-body {
    .card-title {
      background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      padding-bottom: 0.2rem;
      padding-left: 0.3rem;

      .fav-title {
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

.favorite-carousel .card-text {
  color: black;
}

#common-button {
  background-color: white;
  color: #8a00e5;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 4px 2px;
  cursor: pointer;
  border: 2px solid #8a00e5;
  border-radius: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: 0.2s ease;
}

#common-button:hover {
  background-color: #8a00e5;
  color: white;
  border-color: #8a00e5;
}

#common-favbutton {
  background-color: #8a00e5;
  color: white;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 4px 2px;
  cursor: pointer;
  border: 2px solid #8a00e5;
  border-radius: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: 0.2s ease;
}

#common-favbutton:hover {
  background-color: #641e8c;
  color: white;
  border-color: #641e8c;
}

.favorite-carousel .carousel {
  max-width: 100%;
}

.favorite-carousel .carousel-inner {
  position: relative;
  overflow: hidden;
}

.card:hover {
  .card-img-bottom {
    transform: scale(1.5);
  }

  .fav-card-arrow {
    transform: scale(1.5);
  }
}

.favorite-carousel .nav-link {
  position: relative;
}

.favorite-carousel .card-body {
  text-align: left;
  color: white;
}

.favorite-carousel .fav-card-dots {
  z-index: 3;
  position: absolute;
  top: 7%;
  right: 3%;
  transition: transform 0.3s ease;
}

.favorite-carousel .fav-card-arrow {
  position: absolute;
  bottom: 12%;
  right: 7%;
  transition: transform 0.3s ease;
}

.dropdown-toggle {
  border: none;
  color: white;
  background: transparent;
}

.dropdown-toggle .btn:hover {
  border: 0px;
  color: white;
  background: transparent;
}

.fav-dropdown .btn-primary {
  --bs-btn-active-bg: none;
}

.fav-dropdown .dropdown-menu {
  --bs-dropdown-min-width: 5rem;
  --bs-dropdown-padding-x: 0px;
  --bs-dropdown-padding-y: 0px;
  --bs-dropdown-font-size: 0.8rem;
  --bs-dropdown-border-radius: none;
  --bs-dropdown-link-active-color: white;
  --bs-dropdown-link-active-bg: #641e8c;
}

.btn-primary {
  --bs-btn-hover-bg: none;
  --bs-btn-active-border-color: none;
  --bs-btn-active-color: none;
}
.new-stock-img {
  height: 5.5rem;
  width: 100%;
  object-fit: cover;
}
.new-stock-img:focus {
  border: solid 2px black;
  padding: 2px;
}

.stock-img {
  max-height: 5.5rem;
  max-width: 8rem;
  height: 5.5rem;
  width: 8rem;
}

.stock-img:focus {
  border: solid 2px black;
  padding: 2px;
}

.fav-grid {
  .card {
    cursor: pointer;
    --bs-card-spacer-y: 0rem;
    --bs-card-spacer-x: 0rem;
    --bs-card-border-radius: none;
    --bs-card-inner-border-radius: none;
    height: 10.5rem;
    width: 14.7rem;
    border-radius: none;
  }
  .img-hover-zoom {
    z-index: 1;
    overflow: hidden;
    height: 100%;
  }

  .img-hover-zoom .card-img-bottom {
    transition: transform 0.5s ease;
    height: 10.5rem;
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
  }

  .card-title {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    padding-bottom: 0.2rem;
    padding-left: 0.3rem;
  }
  .card-img-overlay {
    z-index: 2;
  }

  .card-text {
    color: black;
  }

  .nav-link {
    position: relative;
  }

  .card-body {
    text-align: left;
    color: white;
  }

  .fav-card-dots {
    z-index: 3;
    position: absolute;
    top: 7%;
    right: 3%;
    transition: transform 0.3s ease;
  }

  .fav-card-arrow {
    position: absolute;
    bottom: 12%;
    right: 7%;
    transition: transform 0.3s ease;
  }
}

.fav-row-btn {
  border-radius: 2rem !important;
  color: #8a00e5;
  background-color: white;
  border-color: #8a00e5;
  border-width: 2px;
  --bs-btn-hover-color: #8a00e5;
  --bs-btn-hover-border-color: #8a00e5;
  --bs-btn-active-bg: white;
}

.activeBtn {
  color: white !important;
  background-color: #8a00e5 !important;
}

.fav-sav-btn {
  color: #8a00e5;
  padding-left: 1rem;
  padding-right: 1rem;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
  background: none;
  border: 2px solid #8a00e5;
  border-radius: 2rem;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8a00e5;
  --bs-btn-hover-border-color: #8a00e5;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8a00e5;
  --bs-btn-active-border-color: #8a00e5;
}
