.main-page-margin {
  padding: 0rem;

  .process-header {
    .text-field {
      overflow-y: hidden;
    }
    .text-field:hover {
      background-color: #fff;
      border-color: none;
    }

    .text-field:focus {
      border-color: none !important;
      box-shadow: none !important;
    }

    .process-btn {
      .main-title-option {
        border: none !important;
        background: none;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .main-title-option :hover {
        border: none !important;
        background-color: none !important;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .active-option {
        background: none !important;
        color: #8a00e5 !important;
        background: none !important;
        border: 0;
        color: black;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .not-active:hover {
        border: none;
        background: none !important;
        color: black !important;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 2rem;
      }

      .main-title-option :active {
        color: #8a00e5;
        background-color: none;
        --bs-btn-active-bg: none;
      }
    }
  }

  .topic-btn-div {
    padding-top: 3rem;
  }

  .topic-table {
    .user-table {
      padding-top: 1rem;

      .table {
        display: block;
        empty-cells: show;
        --bs-table-striped-bg: none !important;

        thead {
          background: #ffff !important;
          color: black;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 0;
        }

        tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: hidden;
          min-height: 38rem;
          text-align: center;

          tr:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        .more-user-btn {
          border: none;
          background: none;
          color: #8a00e5;
          font-size: small;
        }

        .more-user-btn:hover {
          text-decoration: underline;
        }

        tr {
          width: 100%;
          display: flex;
          border: none;
          height: 3.4rem;
        }

        th {
          display: block;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:first-child {
          text-align: left;
          padding-left: 1rem;
          width: 25%;
        }

        th:nth-child(2) {
          text-align: left;
          width: 25%;
          background-color: #ffff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th:nth-child(3) {
          text-align: left;
          width: 20%;
        }

        th:nth-child(4) {
          text-align: left;
          width: 20%;
        }

        th:last-child {
          text-align: left;
          width: 10%;
        }

        td {
          vertical-align: left;
          border: none !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 1rem;
          text-align: left;
          width: 25%;
        }

        td:nth-child(2) {
          text-align: left;
          width: 25%;
        }

        td:nth-child(3) {
          width: 20%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        td:nth-child(4) {
          text-align: left;
          width: 20%;
        }

        td:last-child {
          text-align: left;
          width: 10%;
        }
      }
    }
  }
}

.process-desc-height {
  height: 228px !important;
}

#tooltip-title > .tooltip-inner {
  background-color: black !important;
  color: white;
  max-width: 600px;
  border: 1px solid #062e56;
}
