.generic-card {
  cursor: pointer;
  margin-bottom: 24px;
  .header-section {
    display: flex;
    align-items: center;
  }

  .header-left {
    width: 75%;
  }
  .isHover {
    .header-content {
      --s: 0.1em;
      --c: #641e8c;
      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: background 0.5s, color 0.5s, padding-bottom 0.5s;
    }

    .header-content:hover {
      --_p: 100%;
      color: #000;
    }
  }

  .header-right {
    width: 25%;
    display: flex;
    justify-content: flex-end;
  }

  .image-section {
    height: 180px;
    overflow: hidden;
    .card-image {
      height: 100%;
      object-fit: cover;
      transition: -webkit-transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      width: 100%;
    }
  }
  .isHover {
    img:hover {
      transform: scale(1.1);
    }
  }
  .header-content {
    font-size: 20px;
    font-weight: 600;
    max-height: 2em;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: black;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .description-section {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 15px;
    max-height: 3.5rem;
    min-height: 3.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
