.modal-inline-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.modal-inline-items .custom-item {
  min-width: 70px;
  box-sizing: border-box;
  width: fit-content;
}
