.finDashboard {
  .tabs-section {
    display: "flex";
    flex-direction: "column";
    align-items: "flex-start";

    .tools-tab-btn {
      font-weight: bold;
      font-size: 20px;
      background-color: transparent;
      border: 0px;
      border-radius: 0px;
    }
    .tools-tab-dropdown-btn {
      .btn {
        font-weight: bold;
        font-size: 20px;
        background-color: transparent;
        border: 0px;
        border-radius: 0px;
        color: black;
        &:hover {
          color: rgb(138, 0, 229);
        }
      }
      &.active {
        .dropdown-toggle {
          color: rgb(138, 0, 229) !important;
        }
      }
      .dropdown-item:active {
        background-color: rgb(138, 0, 229) !important;
        color: white !important;
      }
    }
    .fin-menu-link {
      color: black;
      text-decoration: none;
      &:hover {
        color: rgb(138, 0, 229);
      }
    }
    .uiTab {
      border-bottom: 3px solid #8c7500;
      font-weight: 800;
    }
    .intTab {
      border-bottom: 3px solid #006c3b;
      font-weight: 800;
    }
    .sourceTab {
      border-bottom: 3px solid #00328c;
      font-weight: 800;
    }

    .noTab {
      border-bottom: 3px solid #9f9f9f;
      font-weight: 800;
    }

    .active {
      color: rgb(138, 0, 229);
    }
    .finergy-route {
      margin-top: 20px;
    }
  }
  .nav-tabs {
    --bs-nav-tabs-link-hover-border-color: transparent;
    --bs-nav-tabs-link-active-border-color: transparent;
    font-size: 17px;
    font-weight: 600;
    .nav-link {
      color: black;
    }
    .nav-link.active {
      border-bottom: 4px solid black;
    }
  }

  .loader {
    height: 60vh;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .finDashboard .tabs-section {
    .tools-tab-btn {
      margin-bottom: 10px;
      width: 100%;
    }
    .page-header {
      text-align: center;
    }
  }
}
