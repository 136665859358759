.trainings-dashboard {
  .main-comp {
    background-color: "rgb(246, 244, 255)";
    margin-top: 1rem;
  }
  .title-container {
    margin-left: 1rem;
    margin-right: 1rem;

    .graph {
      height: 24rem;
      margin-top: 2rem;
    }
  }
  .trainings-carousel {
    height: 24rem;
    margin-top: 2rem;
  }
}
