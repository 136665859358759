.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 4px auto;
  padding-top: 6px;

  > div {
    width: 9px;
    height: 9px;
    margin: 1px 3px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}
