body {
  margin: 0;
  font: Siemens Sans Global;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Siemens Sans", Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagination {
  --bs-pagination-color: #8900e4 !important;
  --bs-pagination-focus-color: #8900e4 !important;
  --bs-pagination-active-bg: #8900e4 !important;
  --bs-pagination-active-border-color: #8900e4 !important;
  --bs-pagination-hover-color: #8900e4 !important;
}

.main-container {
  width: 100%;
  margin: auto;
}

.tilte-wapper {
  margin: 25px 0 10px 0;

  h2 {
    text-align: left;
    color: #000;
    padding: 5px 0;
    font-size: 22px;
    font-weight: 600;
  }
}

.copy-icon {
  cursor: pointer;
}

.table-scroll {
  display: block;
  empty-cells: show;

  thead {
    position: relative;
    display: block;
    width: 100%;
  }

  tbody {
    display: block;
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: 200px;
  }

  tr {
    width: 100%;
    display: flex;
    border: none;
  }

  th {
    flex-basis: 100%;
    flex-grow: 2;
    display: block;
    text-align: left;
  }

  td {
    width: 35%;
    border: none !important;
  }
}

.nodata {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 30px;
}

.loader-container {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  padding: 0px !important;
  border-radius: 0px !important;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.chatbot-icon {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 7rem;
}

.rcw-full-screen {
  .rcw-conversation-container {
    position: fixed;
    right: 0;
  }
}

.rcw-widget-container {
  z-index: 500 !important;
  .rcw-conversation-container {
    min-width: 40vw;
    max-width: 40vw;
    .rcw-header {
      color: black;
      background-color: #f3e0ff;
      padding: 15px 15px 15px;
      text-align: left;
      border-bottom: 1px solid #8a00e5;
      .rcw-close-button {
        position: fixed;
        top: 4%;
        right: 3%;
        display: block;
        height: 2rem;
        width: 2rem;
        background-color: #8a00e5;
        border: 0;
        border-radius: 50%;
        .rcw-close {
          height: 1.5rem;
          width: 1rem;
        }
      }
      .rcw-title {
        // font-size: 20px;
        padding: 0;
      }
    }
    .rcw-sender {
      background-color: white;
      .rcw-new-message {
        border: 1px solid #d8d8d8;
        width: calc(100% - 30px);

        &:hover {
          border-color: #8a00e5;
        }

        &:active {
          border-color: #8a00e5;
        }

        &:focus-within {
          border-color: #8a00e5 !important;
        }

        .rcw-input {
          margin-left: 5px;
        }
      }

      .rcw-picker-btn {
        display: none;
      }
      .rcw-send {
        border: 1px solid #d8d8d8;
        margin-left: 10px;
        border-radius: 7px;
        height: 2.6rem;
        width: 2.2rem;
        &:hover {
          border-color: #8a00e5;
        }
      }
    }
    .rcw-messages-container {
      .rcw-response {
        .rcw-message-text {
          width: 100%;
          max-width: 32rem;
          background-color: #f3e0ff;
          border-radius: 30px;
        }
      }
      .rcw-client {
        .rcw-message-text {
          width: 100%;
          max-width: 32rem;
          background-color: #f2f2f2;
          border-radius: 30px;
        }
      }

      .rcw-avatar-client {
        border: 1px solid black;
      }
    }

    .loader {
      .loader-container {
        background-color: #f3e0ff;
        min-height: 1rem !important;
        max-height: 1rem !important;
        max-width: 4rem;
        border-radius: 30px;
      }
    }
  }
  .rcw-launcher {
    background-color: #8a00e5;
    height: 4.1rem;
    width: 4.2rem;
    .rcw-open-launcher {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
    }
    .rcw-close-launcher {
      width: 25px;
    }
  }
  .feedback-buttons {
    background: none;
    border: 0px !important;
    --bs-btn-active-bg: white !important;
  }
}

.werner-feedback-modal {
  .modal-content {
    border-radius: 8px !important;
    .modal-header {
      border-bottom: 0px;
      padding-top: 10px;
      .btn-close {
        display: none;
      }
    }
    .modal-body {
      padding-bottom: 0px;
    }
    .modal-footer {
      border-top: 0px;
      padding-bottom: 5px;
    }
  }
}

.app-primary-btn {
  height: 44px !important;
  border: 0px;
  border-radius: 24px;
  color: white;
  background-color: #8a00e5;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #641e8c !important;
  }
  &:active {
    color: white !important;
    background-color: #641e8c !important;
  }
  &:disabled {
    color: white !important;
    background-color: #ada9c2 !important;
  }
}

.app-icon-primary-btn {
  height: 32px !important;
  line-height: 10px;
  border: 0px;
  border-radius: 24px;
  color: white;
  background-color: #8a00e5;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background-color: #641e8c !important;
  }
  &:active {
    color: white !important;
    background-color: #641e8c !important;
  }
  &:disabled {
    color: white !important;
    background-color: #ada9c2 !important;
  }
}

.app-secondary-btn {
  height: 44px !important;
  border: 2px solid #8a00e5;
  border-radius: 24px;
  color: #8a00e5;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #641e8c !important;
    background-color: white !important;
    border-color: #641e8c !important;
  }
  &:active {
    color: #641e8c !important;
    background-color: white !important;
    border-color: #641e8c !important;
  }
  &:disabled {
    color: #ada9c2 !important;
    background-color: white !important;
    border-color: #ada9c2 !important;
  }
}

.app-tertiary-btn {
  border: 0px;
  color: #8a00e5;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0px;
  background-color: transparent !important;
  text-decoration: none !important;
  &:hover {
    transition: 0.2s;
    color: #641e8c !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
    text-decoration-thickness: 2px !important;
  }
  &:active {
    transition: 0.2s;
    color: #641e8c !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
    text-decoration-thickness: 2px !important;
  }
  &:disabled {
    transition: 0.2s;
    color: #ada9c2 !important;
    background-color: white !important;
  }
}

.form-control.is-invalid {
  border: 1.5px solid #da1e28 !important;
}

.app-primary-input-field {
  input {
    height: 40px !important;
    width: 100% !important;
    border: 1.5px solid #969696 !important;
    outline: 0px !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    padding-left: 8px !important;
    position: relative;
    outline-offset: initial;
    -webkit-appearance: auto;
  }
  input:hover {
    border: 1.5px solid #641e8c !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  input:focus {
    border: 1.5px solid #8a00e5 !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  input:active {
    border: 1.5px solid #8a00e5 !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  input:disabled {
    border: 1.5px solid #969696 !important;
    color: #969696 !important;
    background-color: #e9ecef !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    content: url("../../assets/images/search_close.svg");
    cursor: pointer;
  }

  textarea {
    height: 150px;
    border: 1.5px solid #969696;
    outline: 0px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    padding-left: 8px;
    position: relative;
    outline-offset: initial;
    -webkit-appearance: auto;
  }
  textarea:hover {
    border: 1.5px solid #641e8c !important;
    border-radius: 4px !important;
  }
  textarea:focus {
    border: 1.5px solid #8a00e5 !important;
    border-radius: 4px;
    box-shadow: none !important;
  }
  textarea:active {
    border: 1.5px solid #8a00e5 !important;
    border-radius: 4px;
    box-shadow: none !important;
  }

  .rbt {
    height: 40px !important;
    width: 100% !important;
    border: 0px;
    // outline: 0px !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    // padding-left: 8px;
    .rbt-input-main:hover {
      border: 1.5px solid #641e8c !important;
      border-radius: 4px !important;
    }
  }

  .rbt-input-multi {
    height: 40px !important;
    width: 100% !important;
    border: 1.5px solid #969696 !important;
    // outline: 0px !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    padding-left: 8px;

    input {
      height: 25px !important;
      border: 0px !important;
    }
  }
  .rbt-input-multi:hover {
    border: 1.5px solid #641e8c !important;
    border-radius: 4px !important;
  }
  .rbt-input-multi.active {
    border: 1.5px solid #8a00e5 !important;
    border-radius: 4px;
    box-shadow: none !important;
  }
  .rbt-input-multi.focus {
    border: 1.5px solid #8a00e5 !important;
    border-radius: 4px;
    box-shadow: none !important;
  }
}

.app-primary-dropdown {
  .react-dropdown-select {
    min-height: 40px !important;
    width: 100% !important;
    border: 1.5px solid #969696 !important;
    outline: 0px !important;
    border-radius: 4px !important;

    input {
      cursor: pointer;
      font-size: 14px !important;
      line-height: 22px !important;
    }
  }

  .react-dropdown-select:hover {
    border: 1.5px solid #641e8c !important;
    border-radius: 4px !important;
  }

  .react-dropdown-select:focus-within {
    min-height: 40px !important;
    width: 100% !important;
    border: 0.5px solid #8a00e5 !important;
    box-shadow: none !important;
    border-radius: 4px;
  }

  .rbt {
    input {
      min-height: 40px !important;
      width: 100% !important;
      border: 1.5px solid #969696 !important;
      outline: 0px !important;
      border-radius: 4px !important;
      padding-left: 8px !important;
      font-size: 14px !important;

      &:hover {
        border: 1.5px solid #641e8c !important;
        border-radius: 4px !important;
      }

      &:active {
        min-height: 40px !important;
        width: 100% !important;
        border: 0.5px solid #8a00e5 !important;
        box-shadow: none !important;
        background-color: transparent !important;
      }

      &:focus {
        min-height: 40px !important;
        width: 100% !important;
        border: 0.5px solid #8a00e5 !important;
        box-shadow: none !important;
      }
    }

    .rbt-menu {
      .dropdown-item:active {
        background-color: #8a00e5 !important;
      }

      .dropdown-item:hover {
        background-color: #a7a7a7 !important;
      }
    }

    .rbt-menu .dropdown-item.active {
      background-color: #a7a7a7 !important;

      & :hover {
        background-color: #a7a7a7 !important;
      }
    }

    .rbt-input-multi.focus {
      border: 0px !important;
      box-shadow: none !important;
    }

    .form-control {
      border: 0px;
      padding: 0px;
    }
  }

  .form-select {
    min-height: 40px !important;
    width: 100% !important;
    border: 1.5px solid #969696 !important;
    outline: 0px !important;
    border-radius: 4px !important;
  }

  .form-select:hover {
    border: 1.5px solid #641e8c !important;
    border-radius: 4px !important;
  }

  .form-select:focus-within {
    min-height: 40px !important;
    width: 100% !important;
    border: 0.5px solid #8a00e5 !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
}

.rbt-aux {
  .rbt-close {
    .rbt-close-content {
      display: none !important;
    }
  }
}
.app-secondary-dropdown {
  .rbt {
    min-height: 40px !important;
    width: 100% !important;
    border: 1.5px solid #969696 !important;
    outline: 0px !important;
    border-radius: 4px !important;
    padding-left: 8px !important;
    font-size: 14px !important;

    &:hover {
      border: 1.5px solid #641e8c !important;
      border-radius: 4px !important;
    }

    &:active {
      min-height: 40px !important;
      width: 100% !important;
      border: 0.5px solid #8a00e5 !important;
      box-shadow: none !important;
    }

    &:focus {
      min-height: 40px !important;
      width: 100% !important;
      border: 0.5px solid #8a00e5 !important;
      box-shadow: none !important;
    }
    .rbt-input-wrapper {
      margin-top: 6px !important;
    }
    .rbt-menu {
      .dropdown-item:active {
        background-color: #8a00e5 !important;
      }

      inset: 12px auto auto 0px !important;
    }

    .rbt-menu .dropdown-item.active {
      background-color: #a7a7a7 !important;

      &:hover {
        background-color: #a7a7a7 !important;
      }
    }

    .rbt-input-multi.focus {
      border: 0px !important;
      box-shadow: none !important;
    }
    .form-control {
      border: 0px;
      padding: 0px;
      border-radius: 4px;
    }
  }
}

#app-primary-radio {
  .form-check {
    .form-check-input:checked {
      background-color: #8a00e5 !important;
      border-color: #8a00e5 !important;
    }
  }

  .form-check-input:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.tools-table-body {
  height: auto !important;

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #ffff;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-internal-page {
  width: 94%;
}

.page-header {
  h2 {
    color: #8900e3;
  }
}

.tools-page {
  .highchart-comp {
    width: 42%;
  }

  .page-right-section {
    width: 58%;

    .expert-no-data {
      height: 4rem;
    }

    .tool-image {
      height: 2.6rem;
      width: 2.6rem;
      border: 1.5px solid black;
      border-radius: 2rem;
      object-fit: contain;
    }

    .tool-description {
      p {
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
      }
    }

    .carousel {
      width: 100%;

      .carousel-inner {
        max-width: 96%;
        margin-left: 1%;
        overflow: hidden;
      }

      .experts-comp {
        max-width: 25%;
        width: 25%;
        height: 5rem;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 85%;
          font-weight: bold;
          --s: 0.1em;
          --c: #8900e4;
          color: rgba(0, 0, 0, 0);
          padding-bottom: var(--s);
          background: linear-gradient(90deg, var(--c) 50%, #000 0)
              calc(100% - var(--_p, 0%)) / 200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
              no-repeat;
          -webkit-background-clip: text, padding-box;
          background-clip: text, padding-box;
          transition: 0.5s;
        }
      }

      .expert-divider {
        height: 4rem;
        width: 2px;
        background-color: #dee2e6;
      }

      .carousel-control-next {
        color: #8900e3;
        font-size: 20px;
        width: 2rem;
      }

      .carousel-control-prev {
        color: #8900e3;
        font-size: 20px;
        width: 2rem;
        left: -2%;
      }
    }
  }

  .page-connections-section {
    background: #f7f7f7;

    .carousel {
      width: 100%;

      .carousel-inner {
        max-width: 96%;
        margin-left: 1.5rem;
        overflow: hidden;
      }

      .divider {
        height: 5rem;
        width: 2px;
        background-color: #dee2e6;
      }

      .connection-comp {
        cursor: pointer;
        max-width: 18.8%;
        width: 18.8%;
        height: 5rem;

        a {
          text-decoration: none;
        }

        h6 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 95%;
          --s: 0.1em;
          --c: #8900e4;
          color: #8900e4;
          padding-bottom: var(--s);
          background: linear-gradient(90deg, var(--c) 50%, #000 0)
              calc(100% - var(--_p, 0%)) / 200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
              no-repeat;
          -webkit-background-clip: text, padding-box;
          background-clip: text, padding-box;
          transition: 0.5s;
        }

        p {
          color: black;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 95%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .connection-comp:hover {
        --_p: 90%;
        --bs-card-border-color: black;
      }

      .carousel-control-next {
        color: #8900e3;
        font-size: 20px;
        width: 2rem;
        right: -0.5%;
      }

      .carousel-control-prev {
        color: #8900e3;
        font-size: 20px;
        width: 2rem;
        left: -0.5%;
      }
    }
  }
}

.connections-change-section {
  background: #f7f7f7;

  .carousel {
    width: 100%;

    .carousel-indicators {
      top: 10rem;
      color: #8900e4 !important;
    }

    .carousel-indicators .active {
      opacity: 1;
      background-color: #8900e4 !important;
    }
    .carousel-inner {
      max-width: 96%;
      margin-left: 1.5rem;
      overflow: hidden;
      min-height: 12rem;
      padding-top: 1rem;
    }

    .divider {
      height: 8rem;
      width: 2px;
      background-color: #dee2e6;
    }

    .connection-comp {
      cursor: pointer;
      max-width: 18.8%;
      width: 18.8%;
      height: 8rem;

      a {
        text-decoration: none;
      }

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        --s: 0.1em;
        --c: #8900e4;
        color: #8900e4;
        padding-bottom: var(--s);
        background: linear-gradient(90deg, var(--c) 50%, #000 0)
            calc(100% - var(--_p, 0%)) / 200% 100%,
          linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
            no-repeat;
        -webkit-background-clip: text, padding-box;
        background-clip: text, padding-box;
        transition: 0.5s;
      }

      p {
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }

    .connection-comp:hover {
      --_p: 90%;
      --bs-card-border-color: black;
    }

    .carousel-control-next {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      right: -0.5%;
    }

    .carousel-control-prev {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      left: -0.5%;
    }
  }
}
.no-data-comp {
  width: 96.7%;
  height: 5rem;
}

// .main-manage {

.main-page-margin {
  padding: 2rem;

  .back-icon {
    .navigate-back {
      padding-left: 0.1rem;
    }
  }

  .main-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 2rem;
  }

  .sub-bar {
    display: flex;
    flex-direction: row;
    justify-content: left;

    .search-div {
      input {
        border: 1.5px solid #969696;
        border-radius: 2px;
      }

      input:focus {
        outline: none;
        border: 1.5px solid #8a00e5;
        background: none;
        // background-repeat: no-repeat;
        // background-position: right;
        // background-position-x: 13rem
      }

      input :hover {
        background: none;
        border: 2px solid #969696;
      }

      .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: #8a00e5;
        outline: 0;
        box-shadow: none !important;
      }
      input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        content: url("../../assets/images/search_close.svg");
        cursor: pointer;
      }

      .search-bar {
        width: 15rem;
        height: 2.5rem;
        background: url("../../assets/images/search.png");
        background-repeat: no-repeat;
        position: relative;
        height: 40px;
        padding-left: 5px;
        background-size: 23px;
        background-position: right;
        background-position-x: 13rem;
        outline-offset: initial;
        -webkit-appearance: auto;
      }

      .search-bar:hover {
        background: none;
      }
    }

    // .search-bar {
    //   width: 15rem;
    //   height: 2.5rem;
    //   background: url("../../assets/images/search.png");
    //   background-repeat: no-repeat;
    //   position: relative;
    //   height: 40px;
    //   padding-left: 1.7rem;
    //   background-size: 23px;
    //   background-position: left;
    //   background-position-x: 0.3rem;
    // }

    .user-btn {
      float: right;
      color: #8a00e5;
      font-weight: bold;
      background: none;
      border: 2px solid #8a00e5;
      border-radius: 20px;
      height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cancel-btn {
      float: right;
      color: #8a00e5;
      font-weight: bold;
      background: #f7f7f7;
      border: 2px solid #8a00e5;
      border-radius: 20px;
      height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text-field {
    overflow-y: hidden;
  }

  .text-field:hover {
    background-color: #fff;
    border-color: #8a00e5;
  }

  .text-field:focus {
    border-color: #8a00e5 !important;
    box-shadow: none !important;
  }

  .employee-add-btn {
    float: right;
  }

  .employee-add-btn:hover {
    color: white;
  }
}

.expert-no-data {
  height: 4rem;
}

.page-connections-section {
  background: #f7f7f7;

  .carousel {
    width: 100%;

    .carousel-inner {
      max-width: 96%;
      margin-left: 1.5rem;
      overflow: hidden;
    }

    .divider {
      height: 5rem;
      width: 2px;
      background-color: #dee2e6;
    }

    .connection-comp {
      max-width: 18.8%;
      width: 18.8%;
      height: 5rem;
      cursor: pointer;

      a {
        text-decoration: none;
      }

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        --s: 0.1em;
        --c: #8900e4;
        color: #8900e4;
        padding-bottom: var(--s);
        background: linear-gradient(90deg, var(--c) 50%, #000 0)
            calc(100% - var(--_p, 0%)) / 200% 100%,
          linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
            no-repeat;
        -webkit-background-clip: text, padding-box;
        background-clip: text, padding-box;
        transition: 0.5s;
      }

      p {
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .connection-comp:hover {
      --_p: 90%;
      --bs-card-border-color: black;
    }

    .carousel-control-next {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      right: -0.5%;
    }

    .carousel-control-prev {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      left: -0.5%;
    }
  }
}

.experts-section {
  .carousel {
    width: 100%;

    .carousel-inner {
      max-width: 96%;
      margin-left: 1%;
      overflow: hidden;
    }

    .experts-comp {
      max-width: 25%;
      width: 25%;
      height: 5rem;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        font-weight: bold;
        --s: 0.1em;
        --c: #8900e4;
        color: rgba(0, 0, 0, 0);
        padding-bottom: var(--s);
        background: linear-gradient(90deg, var(--c) 50%, #000 0)
            calc(100% - var(--_p, 0%)) / 200% 100%,
          linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
            no-repeat;
        -webkit-background-clip: text, padding-box;
        background-clip: text, padding-box;
        transition: 0.5s;
      }
    }

    .expert-divider {
      height: 4rem;
      width: 2px;
      background-color: #dee2e6;
    }

    .carousel-control-next {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
    }

    .carousel-control-prev {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      margin-left: -22px;
    }
  }
}

.more-user-btn {
  border: none;
  background: none;
  color: #8a00e5;
  font-size: small;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.app-image {
  height: 3rem;
  width: 3rem;
  border: 1.5px solid black;
  border-radius: 2rem;
  object-fit: contain;
}

.expert-no-data {
  height: 4rem;
}

.experts-section {
  .carousel {
    width: 100%;

    .carousel-inner {
      max-width: 96%;
      margin-left: 1%;
      overflow: hidden;
    }

    .experts-comp {
      max-width: 25%;
      width: 25%;
      height: 5rem;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        font-weight: bold;
        --s: 0.1em;
        --c: #8900e4;
        color: rgba(0, 0, 0, 0);
        padding-bottom: var(--s);
        background: linear-gradient(90deg, var(--c) 50%, #000 0)
            calc(100% - var(--_p, 0%)) / 200% 100%,
          linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s)
            no-repeat;
        -webkit-background-clip: text, padding-box;
        background-clip: text, padding-box;
        transition: 0.5s;
      }
    }

    .expert-divider {
      height: 4rem;
      width: 2px;
      background-color: #dee2e6;
    }

    .carousel-control-next {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
    }

    .carousel-control-prev {
      color: #8900e3;
      font-size: 20px;
      width: 2rem;
      margin-left: -22px;
    }
  }
}

.more-user-btn {
  border: none;
  background: none;
  color: #8a00e5;
  font-size: small;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.app-image {
  height: 3rem;
  width: 3rem;
  border: 1.5px solid black;
  border-radius: 2rem;
  object-fit: contain;
}

.app-preview-image {
  height: 485px;
  width: 100%;
  padding-top: 1rem;
  object-fit: contain;
}

.appList {
  height: auto;
  max-height: 800px !important;
  overflow-y: scroll;
  margin-bottom: 10px;

  .appTag {
    font-size: 12px;
    line-height: 24px;
    color: #1b1734;
    padding: 6px 0 6px 5px;
    margin: 0px 20px;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      color: #8a00e5;
      font-weight: bold;
    }

    &.act {
      color: #8a00e5;
      font-weight: bold;
      border-left: 2px solid #8a00e5;
      border-radius: 0;
    }
  }
}

.disabled-field {
  background-color: #e9ecef !important;
  opacity: 1;
}

.disabled-field :hover {
  background-color: #e9ecef !important;
  opacity: 1;
}

.training-desc {
  width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.trainings-link {
  display: block;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1;
  line-height: 1.2;
  text-align: left;
  // width: 90%;
  overflow-wrap: initial;
  text-decoration: none;
  white-space: nowrap;
  // width: 95%;
  font-weight: bold;
  --s: 0.1em;
  --c: #8a00e5;
  color: #8a00e5;
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.35s;

  &:hover {
    color: #8a00e5;
    --_p: 100%;
  }
}

.download-file {
  display: block;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1;
  line-height: 1.2;
  text-align: left;
  overflow-wrap: initial;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;
  margin-top: "1px";
  --s: 0.1em;
  --c: #8a00e5;
  color: #8a00e5;
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.35s;
  border: none;

  &:hover {
    color: #8a00e5 !important;
    --_p: 100%;
  }
}

.trainings-links-modal {
  max-height: 350px;
  overflow-y: auto;
}

.training-image {
  height: 5.3rem;
  width: 5.3rem;
  border-radius: 3rem;
  border: 1px solid black;
  object-fit: contain;
}

.app-new-btn {
  color: #8a00e5;
  cursor: pointer;

  &:hover {
    color: #641e8c;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.app-table-name {
  color: black !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
  }
}

.common-cancel-btn {
  float: right;
  color: #8a00e5;
  font-weight: bold;
  background: none;
  border: 2px solid #8a00e5;
  border-radius: 20px;
  height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  --bs-btn-hover-color: #641e8c;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: #641e8c;
  --bs-btn-active-color: #641e8c;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: #641e8c;
}

.org-emp-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  --s: 0.1em;
  --c: black;
  color: black;
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
}

.org-emp-comp {
  width: 22%;
  border: 1px solid #c0c0c0;
  border-radius: 3rem;
}

.org-act-comp {
  border: 2px solid #8a00e5;
  background-color: #f5e6ff;
}

.org-search-bar {
  width: 15rem;
  height: 2.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAyhJREFUWIXFl1FS2zAQhv+VHZxhmGmOkJ6g4QSEEwAnKHk0ngzNCRJOIBiP40fgBB1OQHqCujfwEdI3O4y8fUChioltkQT4n5K1vP60kla7BAvFcdxVSp0KIY4A9AB0jccJgJSZHxaLxWw0GqU2PqtEdQ/DMOw7jjMG0Ld2SHSXZdnVpmBrgaSUHc/zxgB+bOJUg01837/aGkhK2fU87xGrywIAYOY5EaVElOj/HT2uVwE1y7LsbDQazTcCqoGZKaWuDg4OksFg8Mp5HMfdoij6RDRe826S5/mxLdQLkF6m36ZDHZGri4uLaxtnABBF0USD/f8I0cz3/WOb98XyR7vdllidXSqEOHwLDAAEQTBRSp0x80tEmLk/nU6t9iMBQBRFPSL6bTiYCyEOfd/f+AhHUXRORLeGaZ7n+dempRMAIISQJftoGxgACILgjplvDFNnb2+vMUpCStllZjPPJEEQ3G0Ds9RisZiYS0dEl41ArVbr1DSUZrWV9PKsRCkMw9okKxzHOTENu4rOUkVRzFY+KMRp1VgAEDq5AXg+nruEAYDhcDgzl00I8aUWCKtZdquNXCUiMk/WqxugDPTuIiLriQoznO8lZq6NiilhhpOZ116SO5AJ9LcWCMAv439PStmpGryJysecmWsPjiiKIjENnued7xLIdd3vbwJ6enoq553GbGorfQuYE0yDIEgqXwAgdDa9N2zdKIomuwBqt9srZQgzN1aQAnguN00jEY2bUnyT4jger4lO4y0gAMD3/bRM7zjOz+l0WpvmG2BWJmkTHaBUwsZx/Fi6+UFEkyzL7m26CF0CSwDliczzPD+08VGuqTu6pi7no5SIZkVR3JQ3pZSy47pur9VqnSilzomoKm2kurauhVrXdXQ8z7vF61kCWOk85joDV2ZhPdYEbISqbBR1DXxZ98EapUqpgeu6KTOXu5haqNrONY7jrt6cR5ZgCTM/7O/vXy/bJe3DGqoWyFQYhn0hRJ+IvpWXQSn1h5mT4XC4Ngu/BcoaaFvZQn0YkC3UhwLZQH04UBPUpwBVQRHR7NOAgFdQKRF9XoRKULdENPB9P/0HNzDFoq8C7asAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  position: relative;
  height: 40px;
  padding-left: 5px;
  background-size: 23px;
  background-position: right;
  background-position-x: 13rem;
  outline-offset: initial;
  -webkit-appearance: auto;
  border: 1.5px solid #969696;
  border-radius: 2px;

  input:focus {
    outline: none !important;
    border: 1.5px solid #8a00e5 !important;
    background: none !important;
    // background-repeat: no-repeat;
    // background-position: right;
    // background-position-x: 13rem
  }

  &:hover {
    background: none;
  }

  input :hover {
    background: none;
    border: 2px solid #969696;
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    content: url("../../assets/images/search_close.svg");
    cursor: pointer;
  }
}

.org-search {
  input {
    &:hover {
      border-color: #8a00e5;
    }

    &:focus {
      border-color: #8a00e5;
      box-shadow: none;
    }
  }

  .rbt-menu {
    margin-top: 3px;
    border-radius: 0px;
    padding: 2px;
    // inset: 12px auto auto 0px !important;

    .disabled {
      padding-left: 10px;
    }

    .active {
      color: black;
      background-color: #f5e6ff;
    }
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    content: url("../../assets/images/search_close.svg");
    cursor: pointer;
  }
}

.rbt-token {
  background-color: #8a00e5;
  border: 0;
  border-radius: 0.25rem;
  color: white;
  display: inline-flex;
  line-height: 1rem;
  margin: 1px 3px 10px 0;
}

form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}
#root
  > div
  > div:nth-child(2)
  > div
  > div
  > div.add-and-edit-process
  > div:nth-child(2)
  > form
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div
  > div.rbt-input-multi.form-control.rbt-input
  > div
  > div.rbt-token.rbt-token-removeable
  > button {
  color: white;
}

.limited-space {
  display: -webkit-box;
  margin-bottom: 0px !important;
  margin-left: 15px;
  height: 48px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.read-more-btn {
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.image-disable {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #e9ecef !important;
  padding: 0.7rem;
  border: 1px solid #ced4da;
  margin: 0;
  max-width: 100%;
  width: 100%;
  margin: auto;
  // cursor: no-drop;

  .upload_image_container {
    .upload-container {
      cursor: not-allowed;
      height: 6.8rem;
      margin-left: 3rem;
      position: relative;
      border: none;
      transition: 0.2s box-shadow, 0.2s background-color;
      box-shadow: 0 10px 40px -30px rgb(0 0 0 / 20%);
      min-height: 7rem;

      &.over {
        background-color: #4d217a;
        color: white;
        box-shadow: 0 20px 60px -30px rgba(#2669ff, 1);
      }

      .upload-container {
        border: none;
      }

      h2 {
        transition: 0.2s color;
        text-align: center;
      }

      p {
        margin-bottom: 0px;
        margin-top: 96px;
        margin-left: 0.8rem;
        opacity: 75%;
        margin-top: 0.3rem;
        color: #8900e4;
      }
    }

    .blob-container {
      img {
        max-height: 5rem;
        margin-top: 1rem;
        margin-left: 1.5rem;
      }
    }
  }

  .upload-text {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .para {
    color: #969696;
  }
}

.preview-link {
  a {
    color: #8a00e5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}

.overflow-ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 220px;
}

.user-profile-img {
  height: 2.6rem;
  width: 2.6rem;
  object-fit: contain;
  border: 1.5px solid black;
  border-radius: 7rem;
}

.form-text-field {
  max-height: 196px;
  overflow-y: hidden;
}

.form-text-field:hover {
  background-color: #fff;
  border-color: #8a00e5;
}

.form-text-field:focus {
  border-color: #8a00e5 !important;
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.not-read-more {
  height: none !important;
  margin-bottom: 0 !important;
}

.min-width {
  min-width: 160px;
}

.topics-modal {
  width: 70vw !important;
  max-width: 70vw !important;
}

.disabled-search-bar {
  background-color: #e9ecef !important;
}
.no-bg {
  background-size: 0px !important;
}

.add-admin-user-modal {
  .modal-content {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: visible !important;
  }
  .modal-body {
    padding-bottom: 5px;
    overflow-y: visible;
  }
}

.navbar-dropdown {
  padding-right: none !important;
  #collapsible-nav-dropdown {
    padding-right: 0 !important;
  }
}

.members-download {
  cursor: pointer;
  &:hover {
    border: 2px solid #8a00e5;
    border-radius: 30px;
    margin: -2px;
  }
}

.werner-links {
  color: #8a00e5;
  border: 1px solid #8a00e5;
  text-decoration: none;
  border-radius: 5px;
}

.werner-links:hover {
  color: #641e8c;
  background-color: rgb(138, 0, 229, 0.2);
}

.layers-section {
  height: 40rem;
  //   width: 100%;
  .list-section {
    background-color: #f8f8f8;

    .select-tool {
      display: block;
      width: 86%;
    }

    .tools-list {
      height: 33rem;
      overflow-y: scroll;
    }
    .select-layer {
      .tool-details {
        width: 86%;
      }
    }

    .app-search-box {
      width: 86%;
      form {
        color: #1b1734;
        display: flex;
        padding: 2px;
        border: 1px solid #cfcfcf;
        border-radius: 2px;
        padding-left: 0px;
      }
      input.search-app {
        width: 99%;
        border: 1px solid #cfcfcf;
        display: block;
        padding: 9px 4px 9px 40px;
        background: transparent
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
          no-repeat 13px center;
      }
    }

    .add-tool-btn {
      width: 86% !important;
    }
    width: 86%;

    .tool-desc {
      width: 86%;
      input {
        height: 1rem;
      }
    }
    .buttons-section {
      width: 86%;
      font-weight: bold;
      .btn-1 {
        background: transparent;
        color: #8c7500;
        border: 1.5px solid #8c7500;
        border-radius: 3rem;
        &:hover {
          background-color: #8c7500;
          color: white;
        }
      }
      .act-btn-1 {
        background-color: #8c7500;
        color: white;
      }

      .btn-2 {
        background: transparent;
        color: #006c3b;
        border: 1.5px solid #006c3b;
        border-radius: 3rem;
        &:hover {
          background-color: #006c3b;
          color: white;
        }
      }
      .act-btn-2 {
        background-color: #006c3b;
        color: white;
      }
      .btn-3 {
        background: transparent;
        color: #00328c;
        border: 1.5px solid #00328c;
        border-radius: 3rem;
        &:hover {
          background-color: #00328c;
          color: white;
        }
      }
      .act-btn-3 {
        background-color: #00328c;
        color: white;
      }
    }

    .list-comp {
      width: 86%;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding-bottom: 0.2rem;
        padding-left: 0.3rem;
      }
    }

    form {
      color: #1b1734;
      display: flex;
      padding: 2px;
      border: 1px solid #cfcfcf;
      border-radius: 2px;
      padding-left: 20px;
    }
    input[type="search"] {
      border: none;
      background: transparent;
      margin: 0;
      padding: 7px 8px;
      font-size: 14px;
      color: inherit;
      border: 1px solid transparent;
      border-radius: inherit;
    }
    input[type="search"]::placeholder {
      color: rgba(33, 37, 41, 0.6);
    }
    input[type="search"]:focus {
      box-shadow: 0 0 2px 0 #8a00e5;
      border-color: #8a00e5;
      outline: none;
    }
    form.search-app {
      border: none;
    }
    input.search-app {
      width: 93%;
      border: 1px solid #cfcfcf;
      display: block;
      padding: 9px 4px 9px 40px;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f8f8f8;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(217, 217, 217, 1);
      border-radius: 10px;
    }

    div:hover::-webkit-scrollbar-thumb {
      background: gray;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: gray !important;
    }
  }
  .react-flow-section {
    .flow-canvas {
      height: 100%;
    }
    background-color: #f8f8f8;
  }
}

.profile-img {
  height: 2rem;
  width: 2rem;
  border: 1.5px solid black;
  border-radius: 7rem;
  object-fit: contain;
}
.mail-to {
  color: #000 !important;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #8a00e5 !important;
  }
}
