.generic-tags {
  position: relative;

  .tag-item {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
  }

  .tag-dropdown {
    position: relative;
    display: inline-block;
  }

  .custom-dropdown {
    --bs-dropdown-min-width: 33rem;
    padding: 1rem;
  }

  .tag-dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;
    padding: 5px 0;
    width: 100%;
    min-width: max-content;
  }

  .tag-dropdown:hover .tag-dropdown-menu {
    display: block;
  }

  .tag-dropdown-item {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    flex-direction: column;
    padding: 5px;
    cursor: pointer;
  }

  .tag-dropdown-item:hover {
    background-color: #f5f5f5;
  }
  .more-user-btn:hover {
    text-decoration: underline;
  }

  .trigger-modal {
    padding-top: 3px;
  }
}
