.finDashboard {
  .MuiRating-iconEmpty {
    display: none;
  }

  .MuiRating-root {
    display: inline-flex;
  }

  .disabled-rating .MuiRating-iconFilled {
    color: #969696;
  }

  .custom-rating {
    font-size: 1.25rem;
  }

  @media (max-width: 767px) {
    .custom-rating {
      font-size: 1.1rem;
    }

    .rating .fw-bold {
      font-size: 0.875rem;
    }
  }
  .title-cell {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .left-border {
    border-right: 1px solid #c0c0c0 !important;
  }

  .form-select-btn {
    font-size: 18px;
    color: #8a00e5;
    background: white;
    border-width: 2px;
    border-color: #8a00e5;
    border-radius: 0px;
    --bs-btn-hover-color: #8a00e5;
    --bs-btn-hover-border-color: #8a00e5;
    --bs-btn-active-color: #8a00e5;
    --bs-btn-active-bg: white;
  }

  .form-select-activeBtn {
    color: white !important;
    background-color: #8a00e5 !important;
  }
  .tags {
    background-color: #372ca2;
    color: #fff;
    padding: 10px 10px;
    border-radius: 1.25rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    vertical-align: middle;
  }

  .card-tags {
    background-color: #372ca2;
    color: #fff;
    padding: 10px 10px;
    border-radius: 1.25rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    vertical-align: middle;
    width: fit-content;
  }

  .dropdown-tags {
    background-color: #372ca2;
    color: #fff;
    padding: 10px 10px;
    border-radius: 1.25rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    vertical-align: middle;
    width: fit-content;
  }
  .redirect-btn {
    cursor: pointer;
    width: 50px;
    height: 25px;
    color: #8a00e5;

    &:hover {
      cursor: pointer;
    }
  }
  .rating_none {
    color: grey;
    height: 25px;
    width: 30px;
  }
  .rating_full {
    color: #e7e73f;
    height: 25px;
    width: 30px;
  }

  .more-user-btn {
    white-space: nowrap;
  }

  .owner-profile {
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    .more-user-btn {
      font-size: 0.9rem;
    }
  }

  .custom-loader {
    height: 60vh;
    display: flex;
    justify-content: center;
  }
}

.learning-tags {
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #372ca2;
  color: #fff;
  padding: 10px 10px;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  vertical-align: middle;
  width: fit-content;

  &:hover {
    background-color: #372ca2 !important;
    color: #fff;
  }
}

.empty-tags {
  height: 3.5rem;
  background: transparent;
}

.nested-list-container {
  max-height: 55rem !important;
  overflow-y: auto;
}

.other-view {
  .table-row {
    cursor: pointer;
  }
}
