.download-icon {
  color: #8a00e5;
}

.process-btn {
  text-decoration: none;
}

.process-title {
  font-size: 20px;
}

.value-seperator {
  color: #8a00e5;
}

.desc-overflow {
  max-height: 100px;
  overflow: hidden;
  .pro-desc {
    white-space: pre-line;
  }
}

.read-more-btn {
  color: #8a00e5 !important;
}
.pro-connections {
  width: 95%;
}

.grid-links {
  width: 33%;
}

.link-icon {
  color: #8a00e5;
}

.custom-loader {
  max-width: 100%;
  height: 58rem;
}

.banner-carousel {
  .connections-change-section {
    .no-data-container {
      height: 12rem;
      .no-data {
        height: 8rem;
      }
    }
  }
}
