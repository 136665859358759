.org-modal-container {
  .org-list {
    text-decoration: none;

    .org-list-item {
      font-size: 16px;
      font-weight: 500;
    }

    .org-list-item:hover {
      font-size: 16px;
      font-weight: 500;
      pointer-events: cursor;
      color: #8a00e5;
    }

    .list-group-item {
      color: none !important;
      text-decoration: none;
      background-color: none !important;
      border: none !important;
    }
  }
}
