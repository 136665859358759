.floatingButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #8a00e5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
.werner-feedback-modal {
  z-index: 9999;
}
.back-button {
  cursor: pointer;
}
.icon {
  width: 50px;
}
.default-msg {
  font-size: 12px;
}
.close-icon {
  width: 40px;
  height: 20px;
}
.category-badge {
  margin: 2px;
  cursor: pointer;
  font-size: 12px;
  color: #000;
  border: 2px solid #8a00e5;
  text-align: center;
}
.chatbot-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: min(6em, 20%);
  right: 20px;
  width: 90%;
  max-width: 750px;
  height: 65%;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.chatbot-header {
  padding: 10px;
  height: 70px;
  background-color: #f3e0ff;
  border-bottom: 1px solid #8a00e5;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  position: sticky;
  top: 0;
  .chatbot-header-icon {
    border: none;
    background: none;
  }
}

.messagesContainer {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
}

.fullScreen {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: white;

  .messagesContainer {
    padding: 10px;
    max-height: calc(100% - 70px);
    min-height: calc(100% - 70px);
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .center-text {
    text-align: center;
    font-size: 14.5px;
  }
}

.userMessage {
  flex-direction: row-reverse;
  text-align: right;
}
.center-text {
  text-align: center;
  margin-bottom: 120px;
}
.botMessage {
  justify-content: flex-start;
  text-align: left;
}

.msg-icon {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  border-radius: 50%;
}
.icon {
  margin: 0 10px;
  border-radius: 50%;
}

.message-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f3e0ff;
  margin: 0px;
  margin-left: 10px;
  box-sizing: border-box;
  display: inline-block;
  max-width: 60%;
  word-wrap: break-word;
  font-size: 14.5px;
}

.userMessage .message-bg {
  background-color: #f2f2f2;
  color: #333;
}

.botMessage .message-bg {
  background-color: #f3e5f5;
  color: #333;
}

.loader {
  font-style: italic;
  color: #999;
}

.categories {
  margin-bottom: 4rem;
}
.chatbot-footer {
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.inputContainer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  flex-wrap: wrap;
}

.inputContainer .input {
  flex: 1;
  margin-right: 10px;
}

.inputContainer .sendButton {
  flex: 0 0 auto;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.sendButton {
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  height: 2.9rem;
  margin-left: 10px;
  width: 41px;
  background-color: #f4f7f9;
  img {
    width: 20px;
  }
}

.feedback-buttons {
  background: none;
  border: none;
  &:disabled {
    background: none;
    border: none;
  }
}

.feedback-buttons {
  background: none;
  border: 0px !important;
  --bs-btn-active-bg: white !important;
}

.reference-link {
  cursor: pointer;
}

.page-link {
  z-index: 0 !important;
}

@media (max-width: 768px) {
  .chatbot-container {
    width: 100%;
    height: 70%;
    bottom: 10%;
    right: 0;
    border-radius: 0;
  }

  .chatbot-header {
    height: 60px;
    padding: 5px;
  }

  .messagesContainer {
    padding-top: 60px;
  }

  .chatbot-footer {
    flex-direction: column;
    padding: 10px;
  }

  .inputContainer {
    flex-direction: column;
    padding: 5px;
  }

  .inputContainer .input {
    margin-bottom: 10px;
  }

  .sendButton {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .chatbot-container {
    height: 90%;
  }

  .chatbot-header {
    height: 50px;
    padding: 5px;
  }

  .messagesContainer {
    padding-top: 50px;
  }

  .chatbot-footer {
    padding: 5px;
  }

  .inputContainer {
    padding: 5px;
  }

  .inputContainer .input {
    margin-bottom: 5px;
  }

  .sendButton {
    height: 2.5rem;
  }
}

@media (max-width: 1750px) {
  .center-text {
    text-align: center;
    // margin-bottom: 150px;
  }
}
@media (min-width: 1751px) and (max-width: 2280px) {
  .center-text {
    text-align: center;
    // margin-bottom: 150px;
  }
}

@media (max-width: 1920px) and (max-height: 960px) {
  .fullScreen .center-text {
    text-align: center;
    // margin-bottom: 250px;s
  }
}
