.main-lunch-comp {
  .lunch-carousel {
    .carousel-control-next {
      color: #7e24dc;
      font-size: 2rem;
      width: 2rem;
      left: 100% !important;
    }
    .carousel-indicators {
      top: 23.5rem;
      position: absolute;
    }

    .carousel-control-prev {
      color: #7e24dc;
      font-size: 2rem;
      width: 2rem;
      right: 100% !important;
      left: auto !important;
    }

    .track-vertical {
      position: absolute;
      width: 6px;
      right: 2px;
      bottom: 2px;
      top: 2px;
      border-radius: 3px;
    }
  }
  .dropdown {
    width: 16rem !important;
  }
}
