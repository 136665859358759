.main-layout-container {
  .options-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-width: 99%;

    .layout-container {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .main-content {
      flex: 1;
      display: flex;
    }

    .custom-toggle {
      width: 33%;
    }
  }
  .footer {
    position: sticky;
    bottom: 0;
    padding: 10px;

    .pagination {
      margin-left: 40px;
    }
  }

  .custom-loader {
    height: 71vh;
    display: flex;
    justify-content: center;
  }

  .response-values {
    min-height: 70vh !important;

    .grid-container {
      display: grid;
    }
    .no-data-check {
      padding-top: 7rem;
    }
  }
}

.card-details {
  width: 95% !important;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }
}
