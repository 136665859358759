.responsive-container {
  .back-icon {
    font-weight: bold;
  }
  .main-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 2rem;
  }
}
.edit-icon {
  cursor: pointer;
  margin-right: 10px;
}

.delete-icon {
  cursor: pointer;
}
